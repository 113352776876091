import axios from 'axios';

export const downloadImage = (img: string, fileName: string = '') => {
  const link = document.createElement('a');
  link.href = img;

  if (img.startsWith('data:')) {
    link.download = fileName; // Use provided or default filename
  } else {
    // Extract filename if it's a standard URL
    link.download = img.substring(img.lastIndexOf('/') + 1).split('.')[0];
  }

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadImageHTTPS = async (url: string): Promise<void> => {
  try {
    const response = await axios.get(url, {
      responseType: 'blob',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Cache-Control': 'no-cache',
      },
    });
    const urlBlob = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement('a');
    link.href = urlBlob;
    link.setAttribute('download', 'qr-code.png');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    console.log('Image downloaded successfully.');
  } catch (error) {
    console.error('Failed to download image:', error);
  }
};

export const getStartAndEndDates = (): {
  startDate: string;
  endDate: string;
} => {
  const currentDate = new Date();

  // Calculate the last day of the previous month for endDate
  const lastDayOfPrevMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    0
  ); // 0 gives the last day of the previous month
  const formattedEndDate = lastDayOfPrevMonth.toLocaleDateString('en-CA'); // 'en-CA' gives YYYY-MM-DD format

  // Calculate the current date minus 9 months for startDate
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 9); // Subtract 9 months
  const formattedStartDate = startDate.toLocaleDateString('en-CA'); // 'en-CA' gives YYYY-MM-DD format

  return { startDate: formattedStartDate, endDate: formattedEndDate };
};
