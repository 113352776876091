import { configureStore, combineReducers, Action } from '@reduxjs/toolkit';
import { authReducer, logout } from '../features/authSlice';
import { searchReducer } from '../features/searchSlice';
import { PartnerReducer } from '../features/partnerSlice';
import { ThunkAction } from 'redux-thunk';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistReducer, persistStore } from 'redux-persist';
import { ReportingReducer } from '../features/reportingSlice';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['isAuthenticated', 'user'],
};

const appReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  search: searchReducer,
  partner: PartnerReducer,
  reporting: ReportingReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === logout.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
