import React, { useReducer, useEffect, useState } from 'react';
import envConfig from '../../utils/config';
import Spinner from '../spinner/Spinner';
import { useNavigate } from 'react-router';
import toast, { Toaster } from 'react-hot-toast';
interface State {
  companyName: string;
  addressTwo?: string;
  addressOne?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
  contactEmail: string;
  companyPhone: string;
  phone: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  salesAgentId: string;
  errors: { [key: string]: string };
}

interface SignupFormProps {
  formData: {
    data: any;
    submit: boolean;
    completed: boolean;
  };
  setFormData: React.Dispatch<
    React.SetStateAction<{
      data: any;
      submit: boolean;
      completed: boolean;
    }>
  >;
}

type Action =
  | { type: 'SET_FIELD'; fieldName: string; payload: string }
  | { type: 'SET_ERROR'; fieldName: string; payload: string }
  | { type: 'RESET' };

const initialState: State = {
  companyName: '',
  addressTwo: '',
  addressOne: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
  contactEmail: '',
  companyPhone: '',
  phone: '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  salesAgentId: '',
  errors: {},
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_FIELD':
      return {
        ...state,
        [action.fieldName]: action.payload,
        errors: { ...state.errors, [action.fieldName]: '' },
      };
    case 'SET_ERROR':
      return {
        ...state,
        errors: { ...state.errors, [action.fieldName]: action.payload },
      };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};
interface ModalProps {
  isLoading: boolean;
  children: React.ReactNode;
}
const Modal: React.FC<ModalProps> = ({ children, isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className='fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50'>
      <div
        className={`bg-[#fff0] p-8 rounded-lg  relative w-[full] md:w-[27rem] px-4 md:px-8 py-12 text-center m-4 flex justify-center`}
      >
        <p className='text-base text-gray-700'>{children}</p>
      </div>
    </div>
  );
};

const SignupForm: React.FC<SignupFormProps> = ({ formData, setFormData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...formData.data, // Spread formData.data to update initial state
  });
  const [isValidSalesAgent, setIsValidSalesAgent] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formData.data) {
      dispatch({ type: 'RESET' });
      Object.entries(formData.data).forEach(([key, value]) => {
        dispatch({
          type: 'SET_FIELD',
          fieldName: key,
          payload: value as string,
        });
      });
    }
  }, [formData.data]);

  useEffect(() => {
    const validateFields = () => {
      console.log('inside validate fields');
      let isValid = true;
      const requiredFields = [
        'companyName',
        'contactEmail',
        'companyPhone',
        'firstName',
        'lastName',
        'email',
        'phone',
        'password',
        'confirmPassword',
        'salesAgentId',
      ];

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phonePattern =
        /^\+?[0-9]{1,4}[\s-]?\(?[0-9]{1,5}\)?[\s-]?[0-9]{1,9}[\s-]?[0-9]{1,9}$/;
      const passwordPattern = /^(?=.*[A-Z])(?=.*[\W_]).{8,}$/;
      const salesIdPattern = /^[0-9]{5}$/;

      requiredFields.forEach(field => {
        if (!state[field as keyof State]) {
          dispatch({
            type: 'SET_ERROR',
            fieldName: field,
            payload: `${field.replace(/([A-Z])/g, ' $1')} is required`,
          });
          isValid = false;
        }
      });

      if (state.contactEmail && !emailPattern.test(state.contactEmail)) {
        dispatch({
          type: 'SET_ERROR',
          fieldName: 'contactEmail',
          payload: 'Invalid email address',
        });

        isValid = false;
        console.log(167, isValid);
      } else if (state.email && !emailPattern.test(state.email)) {
        dispatch({
          type: 'SET_ERROR',
          fieldName: 'email',
          payload: 'Invalid email address',
        });
        isValid = false;
        console.log(177, isValid);
      } else if (state.companyPhone && !phonePattern.test(state.companyPhone)) {
        dispatch({
          type: 'SET_ERROR',
          fieldName: 'companyPhone',
          payload:
            'Invalid phone number, Phone number should be (xxx) (xxx-xxxx)',
        });
        isValid = false;
        console.log(185, isValid);
      } else if (state.phone && !phonePattern.test(state.phone)) {
        dispatch({
          type: 'SET_ERROR',
          fieldName: 'phone',
          payload:
            'Invalid phone number, Phone number should be (xxx) (xxx-xxxx)',
        });
        isValid = false;
        console.log(195, isValid);
      } else if (state.password !== state.confirmPassword) {
        dispatch({
          type: 'SET_ERROR',
          fieldName: 'confirmPassword',
          payload: 'Passwords do not match',
        });
        isValid = false;
        console.log(203, isValid);
      } else if (state.password && !passwordPattern.test(state.password)) {
        dispatch({
          type: 'SET_ERROR',
          fieldName: 'password',
          payload:
            'Password must be at least 8 characters long, include at least one uppercase letter and one special character',
        });
        isValid = false;
        console.log(212, isValid);
      } else if (
        state.salesAgentId &&
        !salesIdPattern.test(state.salesAgentId)
      ) {
        dispatch({
          type: 'SET_ERROR',
          fieldName: 'salesAgentId',
          payload: 'Sales Agent ID must be a 5-digit number',
        });
        isValid = false;
        console.log(223, isValid);
      }
      // else {
      //   isValid = true;
      // }
      console.log(220, isValid);
      return isValid;
    };

    const handleRegistration = async (formData: any) => {
      localStorage.setItem('userData', JSON.stringify(formData));

      try {
        setIsValidSalesAgent(false);
        const result = await fetch(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/usr/p/c`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': `${envConfig.REACT_APP_ADMIN_X_API_KEY}`,
            },
            body: JSON.stringify(formData),
          }
        );

        if (result.status === 200) {
          setFormData(prevState => ({
            ...prevState,
            submit: false,
            completed: true,
          }));
        }
        const data = await result.json(); // Await the response body

        if (result.status === 400) {
          toast.error(`${data.message}, Please try a valid Agent ID`);
          setIsValidSalesAgent(true);
        }

        localStorage.setItem('partnerId', data.partnerId);
        if (result.status === 409) {
          if (data) {
            toast.error(data.message);
          }
        }

        return data; // Return the parsed data, not just the result
      } catch (error) {
        console.log('REG ERROR', error);
        return error;
      } finally {
        setIsLoading(false); // Ensure loading state is updated
      }
    };
    console.log(263, formData.submit);
    if (formData.submit) {
      console.log(265);
      const submitHandler = async () => {
        // setModalVisible(true);
        setIsLoading(true);
        setFormData(prevState => ({
          ...prevState,
          submit: false,
        }));
        if (validateFields()) {
          setFormData(prevState => ({
            ...prevState,
            submit: false,
          }));
          const response: any = await handleRegistration(formData.data);
          if (response.status === 409) {
          }
        } else {
          console.log('Form has errors!');
          setFormData(prevState => ({
            ...prevState,
            submit: false,
          }));
          setIsLoading(false);
        }
      };
      submitHandler();
    }
  }, [formData.submit, formData.data, state, dispatch, setFormData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({
      type: 'SET_FIELD',
      fieldName: name,
      payload: value,
    });

    // Update the formData state in the parent component directly
    setFormData(prevFormData => ({
      ...prevFormData,
      data: {
        ...prevFormData.data,
        [name]: value,
      },
    }));
  };

  return (
    <>
      <form>
        <div className='md:container justify-center'>
          <div className='bg-white shadow-md rounded-lg w-full'>
            <div className='mb-8'>
              <div className='mb-6'>
                <h2 className='text-white bg-primary p-4 px-8 rounded-t-lg text-lg font-semibold'>
                  Company Information
                </h2>
                <div className='p-4 md:p-8 border border-gray-200 rounded-b-lg'>
                  <div className='mb-0 mt-4'>
                    <label className='block text-[#000000] text-sm font-[500] mb-2'>
                      Company/Organization Name *
                    </label>
                    <input
                      type='text'
                      name='companyName'
                      value={state.companyName || ''}
                      placeholder='Enter Company Name'
                      className='w-full md:w-[48%] register-input'
                      onChange={handleInputChange}
                    />
                  </div>
                  {state.errors.companyName && (
                    <span className='text-red-600 text-[0.75rem] font-semibold'>
                      {state.errors.companyName}
                    </span>
                  )}
                  <div className='mb-4 mt-4 md:mt-8'>
                    <label className='block text-[#000000] text-sm font-[500] mb-2'>
                      Company Mailing Address
                    </label>
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4'>
                      <input
                        type='text'
                        name='addressOne'
                        value={state.addressOne || ''}
                        placeholder='Address'
                        className='register-input'
                        onChange={handleInputChange}
                      />
                      <input
                        type='text'
                        name='addressTwo'
                        value={state.addressTwo || ''}
                        placeholder='Suite/Apt/Unit (if applicable)'
                        className='register-input'
                        onChange={handleInputChange}
                      />
                      <input
                        type='text'
                        name='city'
                        value={state.city || ''}
                        placeholder='City'
                        className='register-input'
                        onChange={handleInputChange}
                      />
                      <input
                        type='text'
                        name='state'
                        value={state.state || ''}
                        placeholder='State'
                        className='register-input'
                        onChange={handleInputChange}
                      />
                      <input
                        type='text'
                        name='zipCode'
                        value={state.zipCode || ''}
                        placeholder='Zip-code'
                        className='register-input'
                        onChange={handleInputChange}
                      />
                      <input
                        type='text'
                        name='country'
                        value={state.country || ''}
                        placeholder='United States'
                        className='register-input'
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className='mb-4 mt-4 md:mt-8'>
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4'>
                      <div className='self-start'>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          General contact email *
                        </label>
                        <input
                          type='email'
                          className='register-input w-full my-0'
                          name='contactEmail'
                          value={state.contactEmail || ''}
                          onChange={handleInputChange}
                        />
                        {state.errors.contactEmail && (
                          <span className='text-red-600 text-[0.75rem] font-semibold'>
                            {state.errors.contactEmail}
                          </span>
                        )}
                      </div>
                      <div className='self-end'>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Company Phone Number *
                        </label>
                        <input
                          type='text'
                          name='companyPhone'
                          value={state.companyPhone || ''}
                          placeholder=''
                          className='register-input w-full '
                          onChange={handleInputChange}
                        />
                        {state.errors.companyPhone && (
                          <span className='text-red-600 text-[0.75rem] font-semibold'>
                            {state.errors.companyPhone}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Contact Person Form */}
        <div className='md:container justify-center'>
          <div className='bg-white shadow-md rounded-lg w-full'>
            <div className='mb-8'>
              <div className='mb-6'>
                <h2 className='text-white bg-[#1133BB] p-4 px-8 rounded-t-lg text-lg font-semibold'>
                  Contact Details
                </h2>
                <div className='p-4 md:p-8 border border-gray-200 rounded-b-lg'>
                  <div className='mb-0 mt-4'>
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          First Name *
                        </label>
                        <input
                          type='text'
                          className='register-input w-full'
                          name='firstName'
                          value={state.firstName || ''}
                          onChange={handleInputChange}
                        />
                        {state.errors.firstName && (
                          <span className='text-red-600 text-[0.75rem] font-semibold'>
                            {state.errors.firstName}
                          </span>
                        )}
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Last Name *
                        </label>
                        <input
                          type='text'
                          className='register-input w-full'
                          name='lastName'
                          value={state.lastName || ''}
                          onChange={handleInputChange}
                        />
                        {state.errors.lastName && (
                          <span className='text-red-600 text-[0.75rem] font-semibold'>
                            {state.errors.lastName}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='mb-0 mt-4'>
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4'>
                      <div className='self-start'>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Email *
                        </label>
                        <input
                          type='email'
                          className='register-input w-full my-0'
                          name='email'
                          value={state.email || ''}
                          onChange={handleInputChange}
                        />
                        {state.errors.email && (
                          <span className='text-red-600 text-[0.75rem] font-semibold'>
                            {state.errors.email}
                          </span>
                        )}
                      </div>
                      <div className=''>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Phone *
                        </label>
                        <input
                          type='number'
                          name='phone'
                          value={state.phone || ''}
                          placeholder=''
                          className='register-input w-full'
                          onChange={handleInputChange}
                        />
                        {state.errors.phone && (
                          <span className='text-red-600 text-[0.75rem] font-semibold'>
                            {state.errors.phone}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='mb-0 mt-4'>
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Password *
                        </label>
                        <input
                          type='password'
                          className='register-input w-full'
                          name='password'
                          value={state.password || ''}
                          onChange={handleInputChange}
                        />
                        {state.errors.password && (
                          <span className='text-red-600 text-[0.75rem] font-semibold'>
                            {state.errors.password}
                          </span>
                        )}
                        <p className='text-[10px] text-[#878787] leading-[16px] mt-[5px]'>
                          Your password must be at least 8 characters long and
                          include at least one uppercase letter, one number, and
                          one special character.
                        </p>
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Confirm Password *
                        </label>
                        <input
                          type='password'
                          className='register-input w-full'
                          name='confirmPassword'
                          value={state.confirmPassword || ''}
                          onChange={handleInputChange}
                        />
                        {state.errors.confirmPassword && (
                          <span className='text-red-600 text-[0.75rem] font-semibold'>
                            {state.errors.confirmPassword}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='mb-0 mt-4'>
                    <label className='block text-[#000000] text-sm font-[500] mb-2'>
                      Sales Agent ID *
                    </label>
                    <input
                      type='number'
                      className='register-input w-full md:w-[49%]'
                      name='salesAgentId'
                      value={state.salesAgentId || ''}
                      onChange={handleInputChange}
                    />
                  </div>
                  {state.errors.salesAgentId && (
                    <span className='text-red-600 text-[0.75rem] font-semibold'>
                      {state.errors.salesAgentId}
                    </span>
                  )}
                  <p
                    className={`text-[10px]  leading-[16px] mt-[5px] ${
                      isValidSalesAgent
                        ? 'text-red-600 font-semibold'
                        : 'text-[#878787] font-normal'
                    }`}
                  >
                    If you do not have an ID, please{' '}
                    <span
                      className='text-blue-800 cursor-pointer'
                      onClick={() => navigate('/#contact-form')}
                    >
                      click here
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <button
          type='submit'
          className='bg-[#1133BB] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
        >
          Submit
        </button> */}
      </form>
      <Toaster position='top-center' />
      <Modal isLoading={isLoading}>{isLoading && <Spinner />}</Modal>
    </>
  );
};

export default SignupForm;
