import React, { FC, PropsWithChildren } from 'react';
import Spinner from '../spinner/Spinner';

interface Props {
  title: string;
  value: string;
  isMoney?: boolean;
  isLoading?: boolean;
  onClick?: () => void; // Add onClick as an optional prop
}

const Card: FC<PropsWithChildren<Props>> = ({
  title,
  isMoney,
  value,
  onClick,
  isLoading,
}) => {
  return (
    <div
      onClick={onClick}
      className='flex flex-col gap-5 px-6 py-4 border border-gray-300 rounded-xl cursor-pointer'
    >
      <div className='flex justify-between items-center'>
        <p className='text-base font-normal'>{title}</p>
        {isMoney && (
          <svg
            width='21'
            height='21'
            viewBox='0 0 21 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clipPath='url(#clip0_463_51209)'>
              <path
                d='M10.6665 1.50317V19.8365M14.8332 4.83651H8.58317C7.80962 4.83651 7.06776 5.1438 6.52078 5.69078C5.97379 6.23776 5.6665 6.97963 5.6665 7.75317C5.6665 8.52672 5.97379 9.26859 6.52078 9.81557C7.06776 10.3625 7.80962 10.6698 8.58317 10.6698H12.7498C13.5234 10.6698 14.2653 10.9771 14.8122 11.5241C15.3592 12.0711 15.6665 12.813 15.6665 13.5865C15.6665 14.3601 15.3592 15.1019 14.8122 15.6489C14.2653 16.1959 13.5234 16.5032 12.7498 16.5032H5.6665'
                stroke='#98A2B3'
                strokeWidth='1.66667'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </g>
            <defs>
              <clipPath id='clip0_463_51209'>
                <rect
                  width='20'
                  height='20'
                  fill='white'
                  transform='translate(0.666504 0.669922)'
                />
              </clipPath>
            </defs>
          </svg>
        )}
      </div>
      <div>
        {isLoading ? (
          <Spinner />
        ) : (
          <p className='text-xl font-semibold'>{value}</p>
        )}
      </div>
    </div>
  );
};

export default Card;
