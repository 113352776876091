import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../app/store';
import axios from 'axios';
import envConfig from '../utils/config';
import { setUserStatus, fetchUserDetails } from './authSlice';
import { getCookieValue } from '../App';
import toast from 'react-hot-toast';
import { FilterByDate } from '../utils/type';
import { getStartAndEndDates } from '../utils/generalFunctions';
import { handleAxiosError } from '../assets/helper';

interface PartnerState {
  brandInfo: any | null;
  paymentInfo: any | null;
  brandInfoDraft: any | null;
  dashboardInfo: any | null;
  graphMemberCounts: any | null;
  graphNewMembersCounts: any | null;
  graphTransactionCounts: any | null;
  cardEnrollment: any | null;
  paymentInfoDraft: any | null;
  primaryColor: string;
  secondaryColor: string;
  loading: boolean;
  loadingStates: { [key: string]: boolean };
}

const initialState: PartnerState = {
  brandInfo: null,
  paymentInfo: null,
  brandInfoDraft: null,
  dashboardInfo: null,
  graphMemberCounts: null,
  graphNewMembersCounts: null,
  graphTransactionCounts: null,
  cardEnrollment: null,
  paymentInfoDraft: null,
  primaryColor: '#1133BB',
  secondaryColor: '#66CC99',
  loading: false,
  loadingStates: {},
};

export const setLoadingWithTimeout = (
  dispatch: Dispatch,
  loadingKey: string,
  delay: number = 1500
) => {
  setTimeout(() => {
    dispatch(setLoadingState({ key: loadingKey, value: false }));
  }, delay);
};

const PartnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    setBrandInfo: (state, action: PayloadAction<any>) => {
      state.brandInfo = action.payload;
    },
    setPaymentInfo: (state, action: PayloadAction<any>) => {
      state.paymentInfo = action.payload;
    },
    setPrimaryColor: (state, action: PayloadAction<any>) => {
      state.primaryColor = action.payload;
    },
    setSecondaryColor: (state, action: PayloadAction<any>) => {
      state.primaryColor = action.payload;
    },
    setBrandInfoDraft: (state, action: PayloadAction<any>) => {
      state.brandInfoDraft = action.payload;
    },
    setDashboardInfo: (state, action: PayloadAction<any>) => {
      state.dashboardInfo = action.payload;
    },
    setGraphMemberCounts: (state, action: PayloadAction<any>) => {
      state.graphMemberCounts = action.payload;
    },
    setGraphTransactionsCounts: (state, action: PayloadAction<any>) => {
      state.graphTransactionCounts = action.payload;
    },

    setGraphNewMembersCounts: (state, action: PayloadAction<any>) => {
      state.graphNewMembersCounts = action.payload;
    },
    setCardEnrollment: (state, action: PayloadAction<any>) => {
      state.cardEnrollment = action.payload;
    },
    setPaymentInfoDraft: (state, action: PayloadAction<any>) => {
      state.paymentInfoDraft = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoadingState: (
      state,
      action: PayloadAction<{ key: string; value: boolean }>
    ) => {
      state.loadingStates[action.payload.key] = action.payload.value;
    },
  },
});

export const {
  setBrandInfo,
  setPaymentInfo,
  setPrimaryColor,
  setSecondaryColor,
  setBrandInfoDraft,
  setPaymentInfoDraft,
  setLoading,
  setLoadingState,
  setDashboardInfo,
  setGraphMemberCounts,
  setGraphNewMembersCounts,
  setCardEnrollment,
  setGraphTransactionsCounts,
} = PartnerSlice.actions;

export const PartnerReducer = PartnerSlice.reducer;

export const UpdateBrandInfo =
  (brandInfo: FormData): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true)); // Start loading
    const loadingKey = 'UpdateBrandInfoState';
    try {
      const token = getCookieValue('token');
      const { partner } = getState() as { partner: PartnerState };
      if (!partner.loadingStates[loadingKey]) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));
        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/usr/p/b`,
          brandInfo,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
              'Content-Type': 'multipart/form-data',
              'Accept': '*/*',
            },
          }
        );
        if (response.status === 200) {
          toast.success('form saved successfully');
          const brandInfoField = brandInfo.get('brandInfo');
          if (brandInfoField === 'completed') {
            dispatch(setUserStatus('inreview'));
          }
          dispatch(GetBrandInfo());
        }

        return response;
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      return error;
    } finally {
      dispatch(setLoading(false)); // Stop loading
      setLoadingWithTimeout(dispatch, loadingKey);
    }
  };

export const GetDashboardInfo = (): AppThunk => async (dispatch, getState) => {
  const loadingKey = 'GetDashboardInfo';
  try {
    const { partner } = getState() as { partner: PartnerState };
    const token = getCookieValue('token');
    if (!partner.loadingStates[loadingKey] && token) {
      dispatch(setLoadingState({ key: loadingKey, value: true }));

      const response = await axios.post(
        `${envConfig.REACT_APP_ADMIN_BASE_URL}/usr/p/d`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setDashboardInfo(response.data));
      }
    }
  } catch (error) {
    handleAxiosError(error, dispatch);
    return error;
  } finally {
    setLoadingWithTimeout(dispatch, loadingKey);
  }
};

export const GetGraphNewMembersCounts =
  (date?: FilterByDate): AppThunk =>
  async (dispatch, getState) => {
    const loadingKey = 'GetGraphNewMembersCounts';
    try {
      const { partner } = getState() as { partner: PartnerState };
      const token = getCookieValue('token');
      if (!partner.loadingStates[loadingKey] && token) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));

        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/usr/p/d/n/c`,
          date ?? getStartAndEndDates(),
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            },
          }
        );

        if (response.status === 200) {
          dispatch(setGraphNewMembersCounts(response.data));
        }
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      return error;
    } finally {
      setLoadingWithTimeout(dispatch, loadingKey);
    }
  };
export const GetGraphTransactionsCounts =
  (date?: FilterByDate): AppThunk =>
  async (dispatch, getState) => {
    const loadingKey = 'GetGraphTransactionsCounts';
    try {
      const { partner } = getState() as { partner: PartnerState };
      const token = getCookieValue('token');
      if (!partner.loadingStates[loadingKey] && token) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));

        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/usr/p/d/p/t/c`,
          date ?? getStartAndEndDates(),
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            },
          }
        );

        if (response.status === 200) {
          dispatch(setGraphTransactionsCounts(response.data));
        }
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      return error;
    } finally {
      setLoadingWithTimeout(dispatch, loadingKey);
    }
  };

export const GetCardEnrollment =
  (date?: FilterByDate): AppThunk =>
  async (dispatch, getState) => {
    const loadingKey = 'GetCardEnrollment';
    try {
      const { partner } = getState() as { partner: PartnerState };
      const token = getCookieValue('token');
      if (!partner.loadingStates[loadingKey] && token) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));

        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/usr/p/d/c/w`,
          date ?? getStartAndEndDates(),
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            },
          }
        );

        if (response.status === 200) {
          dispatch(setCardEnrollment(response.data));
        }
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      return error;
    } finally {
      setLoadingWithTimeout(dispatch, loadingKey);
    }
  };

export const GetGraphMemberCounts =
  (date?: FilterByDate): AppThunk =>
  async (dispatch, getState) => {
    const loadingKey = 'GetGraphMemberCounts';

    try {
      const { partner } = getState() as { partner: PartnerState };
      const token = getCookieValue('token');
      if (!partner.loadingStates[loadingKey] && token) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));

        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/usr/p/d/m/c`,
          date ?? getStartAndEndDates(),
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            },
          }
        );

        if (response.status === 200) {
          dispatch(setGraphMemberCounts(response.data));
        }
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      return error;
    } finally {
      setLoadingWithTimeout(dispatch, loadingKey);
    }
  };

export const GetBrandInfo = (): AppThunk => async (dispatch, getState) => {
  const loadingKey = 'GetBrandInfoState';
  try {
    const { partner } = getState() as { partner: PartnerState };
    const token = getCookieValue('token');
    if (!partner.loadingStates[loadingKey]) {
      dispatch(setLoadingState({ key: loadingKey, value: true }));
      const response = await axios.post(
        `${envConfig.REACT_APP_ADMIN_BASE_URL}/usr/p/g/b`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
          },
        }
      );
      if (response.status === 200) {
        dispatch(setBrandInfo(response.data));
      }
    }
  } catch (error) {
    handleAxiosError(error, dispatch);
    return error;
  } finally {
    // Set loading to false after the operation is complete
    dispatch(setLoadingState({ key: loadingKey, value: false }));
  }
};

export const UpdateBankInfo =
  (partnerId: string, payoutInfo: FormData): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true)); // Start loading
    const loadingKey = 'UpdateBankInfoState';
    try {
      const { partner } = getState() as { partner: PartnerState };
      const token = getCookieValue('token');
      if (!partner.loadingStates[loadingKey]) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));
        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/usr/p/a/b/d`,
          payoutInfo,
          {
            headers: {
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
        );

        if (
          response.status === 200 &&
          window.location.href.includes('/payout-account')
        ) {
          toast.success('Form saved successfully');
        }

        return response;
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      return error;
    } finally {
      dispatch(setLoading(false)); // Stop loading
      setLoadingWithTimeout(dispatch, loadingKey);
    }
  };

export const GetBankInfo =
  (partnerId: string): AppThunk =>
  async (dispatch, getState) => {
    const loadingKey = 'GetBankInfoState';
    try {
      const { partner } = getState() as { partner: PartnerState };
      const token = getCookieValue('token');
      if (!partner.loadingStates[loadingKey]) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));
        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/usr/p/g/b/d`,
          {},
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            },
          }
        );

        if (response.status === 200) {
          dispatch(setPaymentInfo(response.data));
        } else {
          console.log('Loading in progress, skipping dispatch.');
        }
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      return error;
    } finally {
      // Set loading to false after the operation is complete
      setLoadingWithTimeout(dispatch, loadingKey);
    }
  };

export const ContactUs =
  (formData: any): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    const loadingKey = 'ContactUsState';
    try {
      const { partner } = getState() as { partner: PartnerState };
      if (!partner.loadingStates[loadingKey]) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));
        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/sys/sys/s`,
          formData,
          {
            headers: {
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
              'Content-Type': 'application/json',
            },
          }
        );
        return response;
      }
    } catch (error) {
      console.log('error', error);
      return error;
    } finally {
      dispatch(setLoading(false));
      setLoadingWithTimeout(dispatch, loadingKey);
    }
  };

export const UpdatePartnerInfo =
  (userId: string, partnerInfo: FormData): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    const loadingKey = 'UpdatePartnerInfoState';
    partnerInfo.delete('zipcode');
    partnerInfo.delete('salesAgentId');
    try {
      const { partner } = getState() as { partner: PartnerState };
      const token = getCookieValue('token');
      if (!partner.loadingStates[loadingKey]) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));
        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/usr/p/u`,
          partnerInfo,
          {
            headers: {
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          dispatch(fetchUserDetails(userId));
        }

        return response;
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      return error;
    } finally {
      dispatch(setLoading(false));
      setLoadingWithTimeout(dispatch, loadingKey);
    }
  };
export default PartnerSlice.reducer;
