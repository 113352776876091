import React from 'react';
import { downloadImage } from '../../utils/generalFunctions';

interface ImageHoverCardProps {
  imageUrl: string;
  altText: string;
  imgObjectType?: 'object-contain' | 'object-cover' | 'object-fill';
}

const ImageHoverCard: React.FC<ImageHoverCardProps> = ({
  imageUrl,
  altText,
  imgObjectType = 'object-cover',
}) => {
  return (
    <div
      className={`relative group h-[15rem] overflow-hidden rounded-[10px] ${
        imgObjectType !== 'object-contain' && 'w-[21.25rem]'
      }`}
    >
      <img
        src={imageUrl}
        alt={altText}
        className={`w-full h-full ${imgObjectType}`}
      />
      <div className='absolute bottom-0 left-0 w-full h-14 bg-black bg-opacity-60 flex items-center justify-between px-4 lg:opacity-0 lg:group-hover:opacity-100 lg:transition-opacity lg:duration-300'>
        <span className='text-white text-base font-semibold'>Download</span>
        <button className='' onClick={() => downloadImage(imageUrl)}>
          <svg
            width='26'
            height='26'
            viewBox='0 0 26 26'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect
              width='26'
              height='26'
              rx='3'
              fill='white'
              fillOpacity='0.9'
            />
            <path
              d='M18 14.6667V16.8889C18 17.1836 17.8829 17.4662 17.6746 17.6746C17.4662 17.8829 17.1836 18 16.8889 18H9.11111C8.81643 18 8.53381 17.8829 8.32544 17.6746C8.11706 17.4662 8 17.1836 8 16.8889V14.6667M10.2222 11.8889L13 14.6667M13 14.6667L15.7778 11.8889M13 14.6667V8'
              stroke='black'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ImageHoverCard;
