import {
  MdAttachMoney,
  MdBarChart,
  MdCreditCard,
  MdListAlt,
  MdPeople,
} from 'react-icons/md';
import { LiaQrcodeSolid } from 'react-icons/lia';
import { RiFlag2Line } from 'react-icons/ri';
import { MdOutlineMarkEmailRead } from 'react-icons/md';
import { MdOutlineSocialDistance } from 'react-icons/md';
import { TiImage, TiPrinter } from 'react-icons/ti';
import { LuFileSpreadsheet } from 'react-icons/lu';
import { RiCalendarCheckFill } from 'react-icons/ri';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { VscGraph } from 'react-icons/vsc';
import { MdOutlineBrandingWatermark } from 'react-icons/md';
import { MdPayment } from 'react-icons/md';
import { RiSecurePaymentLine } from 'react-icons/ri';
import { LuUsers2 } from 'react-icons/lu';
import { TbCreditCard } from 'react-icons/tb';
// import { FaFileInvoiceDollar } from 'react-icons/fa6';
import { BiMessageDetail } from 'react-icons/bi';

export const sideBarItems = [
  {
    id: 1,
    name: 'Dashboard',
    path: '/dashboard',
    icon: VscGraph,
    children: [],
  },
  {
    id: 2,
    name: 'Marketing Material',
    path: '',
    icon: MdListAlt,
    children: [
      {
        id: 1,
        name: 'QR & Links',
        path: '/marketing-material/qr',
        icon: LiaQrcodeSolid,
        children: [],
      },
      {
        id: 2,
        name: 'Messaging',
        path: '/marketing-material/messaging',
        icon: BiMessageDetail,
        children: [],
      },
      {
        id: 3,
        name: 'Email',
        path: '/marketing-material/email',
        icon: MdOutlineMarkEmailRead,
        children: [],
      },
      {
        id: 4,
        name: 'Social Media',
        path: '/marketing-material/social-media',
        icon: MdOutlineSocialDistance,
        children: [],
      },
      {
        id: 5,
        name: 'Banners',
        path: '/marketing-material/banners',
        icon: RiFlag2Line,
        children: [],
      },
      {
        id: 6,
        name: 'Print Material',
        path: '/marketing-material/print',
        icon: TiPrinter,
        children: [],
      },
      {
        id: 7,
        name: 'Product Images',
        path: '/marketing-material/product-images',
        icon: TiImage,
        children: [],
      },
    ],
  },
  {
    id: 3,
    name: 'Reporting',
    path: '',
    icon: LuFileSpreadsheet,
    children: [
      {
        id: 3.1,
        name: 'Members Data',
        icon: MdPeople,
        path: '/reporting/customers-data',
        children: [],
      },
      {
        id: 3.2,
        name: 'Transactions Data',
        icon: MdAttachMoney,
        path: '/reporting/transactions-data',
        children: [],
      },
      // {
      //   id: 3.2,
      //   name: 'Total Value Transactions',
      //   icon: IoIosInformationCircleOutline,
      //   path: '/reporting/total-value-transactions',
      //   children: [],
      // },
      {
        id: 3.4,
        name: 'Earnings Data',
        icon: MdBarChart,
        path: '/reporting/earnings-data',
        children: [],
      },
      // {
      //   id: 3.5,
      //   name: 'Customer Total Earnings',
      //   icon: IoIosInformationCircleOutline,
      //   path: '/reporting/customer-earnings',
      //   children: [],
      // },
      {
        id: 3.6,
        name: 'Cards Data',
        icon: MdCreditCard,
        path: '/reporting/cards-data',
        children: [],
      },
    ],
  },
  {
    id: 4,
    name: 'Program Management',
    icon: RiCalendarCheckFill,
    children: [
      {
        id: 1,
        name: 'Company Info',
        icon: IoIosInformationCircleOutline,
        path: '/company-info',
        children: [],
      },
      {
        id: 2,
        name: 'Branding',
        icon: MdOutlineBrandingWatermark,
        path: '/program-branding',
        children: [],
      },

      {
        id: 3,
        name: 'Users',
        path: '/user-management',
        icon: LuUsers2,
        children: [],
      },
      {
        id: 5,
        name: 'Billing',
        icon: TbCreditCard,
        children: [
          {
            id: 5.1,
            name: 'Program Plan',
            icon: MdPayment,
            path: '/program-plan',
            children: [],
          },
          {
            id: 5.2,
            name: 'Payout Account',
            icon: RiSecurePaymentLine,
            path: '/payout-account',
            children: [],
          },
          // {
          //   id: 5.3,
          //   name: 'Invoices',
          //   icon: FaFileInvoiceDollar,
          //   path: '/invoices',
          //   children: [],
          // },
        ],
      },
    ],
  },
];

export const countries = [
  { id: 1, countryName: 'USA', value: 'usa' },
  { id: 2, countryName: 'Lebanon', value: 'lebanon' },
  { id: 3, countryName: 'uae', value: 'UAE' },
  { id: 4, countryName: 'Canada', value: 'canada' },
];

export const data = [
  {
    no: 'Apple MacBook Pro 17',
    amount: 'Silver',
    status: 'Laptop',
    date: 'Yes',
  },
  {
    no: 'Microsoft Surface Pro',
    amount: 'White',
    status: 'Laptop PC',
    date: '7/20/2073',
  },
  {
    no: 'Apple MacBook Pro 17',
    amount: 'Silver',
    status: 'Laptop',
    date: '8/3/2073',
  },
  {
    no: 'Microsoft Surface Pro',
    amount: 'White',
    status: 'Laptop PC',
    date: '8/16/2042',
  },
  {
    no: 'Apple MacBook Pro 17',
    amount: 'Silver',
    status: 'Laptop',
    date: '12/5/2115',
  },
  {
    no: 'Microsoft Surface Pro',
    amount: 'White',
    status: 'Laptop PC',
    date: '1/17/2119',
  },
];

export const config = {
  headers: [
    { key: 'no', label: 'S.No' },
    { key: 'amount', label: 'Amount' },
    { key: 'status', label: 'Status' },
    { key: 'date', label: 'Date' },
  ],
};

export const data2 = [
  {
    no: '106',
    customerId: '48',
    offerName: 'Laptop',
    date: '7/20/2073',
    purchaseAmount: '7',
    cashback: '11%',
  },
  {
    no: '237',
    customerId: '95',
    offerName: 'Laptop PC',
    date: '7/20/2073',
    purchaseAmount: '30',
    cashback: '79%',
  },
  {
    no: '248',
    customerId: '87',
    offerName: 'Laptop',
    date: '8/3/2073',
    purchaseAmount: '9',
    cashback: '85%',
  },
  {
    no: '218',
    customerId: '50',
    offerName: 'Laptop PC',
    date: '8/16/2042',
    purchaseAmount: '26',
    cashback: '59%',
  },
  {
    no: '124',
    customerId: '14',
    offerName: 'Laptop',
    date: '12/5/2115',
    purchaseAmount: '1',
    cashback: '34%',
  },
  {
    no: '218',
    customerId: '55',
    offerName: 'Laptop PC',
    date: '1/17/2119',
    purchaseAmount: '30',
    cashback: '24%',
  },
];

export const config2 = {
  headers: [
    { key: 'no', label: 'S.No' },
    { key: 'customerId"', label: 'CustomerId"' },
    { key: 'offerName', label: 'OfferName' },
    { key: 'purchaseAmount', label: 'PurchaseAmount' },
    { key: 'cashback', label: 'Cashback' },
    { key: 'date', label: 'Date' },
  ],
};

export type UserType = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dateCreated: string;
  role: string;
  status: string;
};

export const usersData: UserType[] = [
  {
    id: 1,
    firstName: 'James',
    lastName: 'Woods',
    email: 'info@example.com',
    phone: '1(555)123-4567',
    dateCreated: '10/30/2024',
    role: 'Admin',
    status: 'Active',
  },
  {
    id: 2,
    firstName: 'Augusta',
    lastName: 'Little',
    email: 'marketing@example.com',
    phone: '1(555)987-6543',
    dateCreated: '8/21/2024',
    role: 'Normal',
    status: 'Active',
  },
  {
    id: 3,
    firstName: 'Marvin',
    lastName: 'Scott',
    email: 'accounts@example.com',
    phone: '1(555)123-4455',
    dateCreated: '3/13/2024',
    role: 'Admin',
    status: 'Inactive',
  },
  {
    id: 4,
    firstName: 'Alejandro',
    lastName: 'Kelly',
    email: 'akelly@example.com',
    phone: '1(555)763-4479',
    dateCreated: '5/1/2024',
    role: 'Normal',
    status: 'Active',
  },
];
export const usersConfig = {
  headers: [
    { key: 'firstName', label: 'First Name' },
    { key: 'lastName', label: 'Last Name' },
    { key: 'phone', label: 'Phone' },
    { key: 'email', label: 'Email' },
    { key: 'dateCreated', label: 'Date Created' },
    { key: 'role', label: 'Role' },
    { key: 'status', label: 'Status' },
  ],
};

export const invoicesData = [
  {
    id: 4,
    date: 'Sept 1, 2024',
    invoiceType: 'Monthly Subscription',
    invoiceValue: '$249',
    status: 'Pending',
  },
  {
    id: 3,
    date: 'August 1, 2024',
    invoiceType: 'Monthly Subscription',
    invoiceValue: '$249',
    status: 'Paid',
  },
  {
    id: 2,
    date: 'July 1, 2024',
    invoiceType: 'Monthly Subscription',
    invoiceValue: '$249',
    status: 'Paid',
  },
  {
    id: 1,
    date: 'May 15, 2024',
    invoiceType: 'Setup Fee Tax Invoice',
    invoiceValue: '$4,995',
    status: 'Paid',
  },
];
export const invoicesConfig = {
  headers: [
    { key: 'date', label: 'Invoice Date' },
    { key: 'invoiceType', label: 'Invoice Type' },
    { key: 'invoiceValue', label: 'Invoice Value' },
    { key: 'status', label: 'Status' },
  ],
};

export const subscriptionPaymentsData = [
  {
    id: 4,
    date: 'Sept 1, 2024',
    paymentType: 'Monthly Subscription',
    paymentValue: '$249',
    status: 'Pending',
  },
  {
    id: 3,
    date: 'August 1, 2024',
    paymentType: 'Monthly Subscription',
    paymentValue: '$249',
    status: 'Paid',
  },
  {
    id: 2,
    date: 'July 1, 2024',
    paymentType: 'Monthly Subscription',
    paymentValue: '$249',
    status: 'Paid',
  },
  {
    id: 1,
    date: 'May 15, 2024',
    paymentType: 'Setup Fee',
    paymentValue: '$4,995',
    status: 'Paid',
  },
];
export const subscriptionPaymentsConfig = {
  headers: [
    { key: 'date', label: 'Payment Date' },
    { key: 'paymentType', label: 'Payment Type' },
    { key: 'paymentValue', label: 'Payment Value' },
    { key: 'status', label: 'Status' },
  ],
};
