import React from 'react';
import { Nullable } from '../../utils/type';

interface RequiredAsteriskProps {
  isEditing: Nullable<boolean>;
}

const RequiredAsterisk: React.FC<RequiredAsteriskProps> = ({ isEditing }) => {
  return isEditing ? <span className='text-red-700'> *</span> : null;
};

export default RequiredAsterisk;
