import React, { useState } from 'react';
import Button from '../../components/button/Button';
import { downloadImage } from '../../utils/generalFunctions';
import emailBanner1 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_1.png';
import emailBanner2 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_2.png';
import emailBanner3 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_3.png';
import emailBanner4 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_4.png';
import emailBanner5 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_5.png';
import emailBanner7 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_7.png';
import emailBanner8 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_8.png';
import emailBanner9 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_9.png';
import emailBanner10 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_10.png';
import emailBanner11 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_11.png';
import emailBanner12 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_12.png';
import emailBanner16 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_16.png';
import emailBanner17 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_17.png';
import emailBanner18 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_18.png';
import emailBanner19 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_19.png';
import emailBanner20 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_20.png';
import emailBanner21 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_21.png';
import emailBanner22 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_22.png';
import emailBanner23 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_23.png';
import emailBanner24 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_24.png';
import emailBanner25 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_25.png';
import emailBanner26 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_26.png';
import emailBanner27 from '../../assets/images/dashboard/330x228/SC_EmailBanner_330x228_AW_27.png';

import emailBanner2_1 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_1.png';
import emailBanner2_2 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_2.png';
import emailBanner2_3 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_3.png';
import emailBanner2_4 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_4.png';
import emailBanner2_5 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_5.png';
import emailBanner2_7 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_7.png';
import emailBanner2_8 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_8.png';
import emailBanner2_9 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_9.png';
import emailBanner2_10 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_10.png';
import emailBanner2_11 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_11.png';
import emailBanner2_12 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_12.png';
import emailBanner2_16 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_16.png';
import emailBanner2_17 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_17.png';
import emailBanner2_18 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_18.png';
import emailBanner2_19 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_19.png';
import emailBanner2_20 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_20.png';
import emailBanner2_21 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_21.png';
import emailBanner2_22 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_22.png';
import emailBanner2_23 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_23.png';
import emailBanner2_24 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_24.png';
import emailBanner2_25 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_25.png';
import emailBanner2_26 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_26.png';
import emailBanner2_27 from '../../assets/images/dashboard/600x288/SC_EmailBanner_600x288_AW_27.png';
import {
  web120x600,
  web160x600,
  web250x250,
  web300x250,
  web300x600,
  web336x280,
  web420x250,
  web1208x744,
  web1272x792,
  web1500x500,
  web1880x360,
  web2400x1350,
  web468x60,
  web715x130,
  web728x90,
  web970x250,
  web970x90,
} from '../../assets/images/webAdImages/webImages';
const webAdSizes = [
  { id: 1, text: 'Half Page: 300x600' },
  { id: 2, text: 'Square: 250x250' },
  { id: 3, text: 'Large Rectangle: 336x280' },
  { id: 4, text: 'In Line Rectangle: 300x250' },
  { id: 5, text: 'Wide Rectangle: 420x250' },
  { id: 6, text: 'Craper: 160x600' },
  { id: 7, text: 'Craper: 120x600' },
  { id: 8, text: 'Billboard: 970x250' },
  { id: 9, text: 'Large Leaderboard: 970x90' },
  { id: 10, text: 'Leaderboard: 728x90' },
  { id: 11, text: 'Leaderboard: 715x130' },
  { id: 12, text: 'Banner: 468x60' },
  { id: 13, text: 'XL Banner: 1208x744' },
  { id: 14, text: 'XL Banner: 1272x792' },
  { id: 15, text: 'XL Banner: 1500x500' },
  { id: 16, text: 'XL Banner: 1880x360' },
  { id: 17, text: 'XL Banner: 2400x1350' },
];

const emailBannerImages1 = [
  emailBanner1,
  emailBanner2,
  emailBanner3,
  emailBanner4,
  emailBanner5,
  emailBanner7,
  emailBanner8,
  emailBanner9,
  emailBanner10,
  emailBanner11,
  emailBanner12,
  emailBanner16,
  emailBanner17,
  emailBanner18,
  emailBanner19,
  emailBanner20,
  emailBanner21,
  emailBanner22,
  emailBanner23,
  emailBanner24,
  emailBanner25,
  emailBanner26,
  emailBanner27,
];

const emailBannerImages2 = [
  emailBanner2_1,
  emailBanner2_2,
  emailBanner2_3,
  emailBanner2_4,
  emailBanner2_5,
  emailBanner2_7,
  emailBanner2_8,
  emailBanner2_9,
  emailBanner2_10,
  emailBanner2_11,
  emailBanner2_12,
  emailBanner2_16,
  emailBanner2_17,
  emailBanner2_18,
  emailBanner2_19,
  emailBanner2_20,
  emailBanner2_21,
  emailBanner2_22,
  emailBanner2_23,
  emailBanner2_24,
  emailBanner2_25,
  emailBanner2_26,
  emailBanner2_27,
];

const Banners = () => {
  const [activeTab, setActiveTab] = useState<'email' | 'web'>('email');
  const [activeId, setActiveId] = useState<number>(1);
  const [firstTabButtons, setFirstTabButtons] = useState(1);
  const renderedPhotos = () => {
    let rendered: string[] = [];
    switch (activeId) {
      case 1:
        rendered = web300x600; // Half Page: 300x600
        break;
      case 2:
        rendered = web250x250; // Square: 250x250
        break;
      case 3:
        rendered = web336x280; // Large Rectangle: 336x280
        break;
      case 4:
        rendered = web300x250; // In Line Rectangle: 300x250
        break;
      case 5:
        rendered = web420x250; // Wide Rectangle: 420x250
        break;
      case 6:
        rendered = web160x600; // Craper: 160x600
        break;
      case 7:
        rendered = web120x600; // Craper: 120x600
        break;
      case 8:
        rendered = web970x250; // Billboard: 970x250
        break;
      case 9:
        rendered = web970x90; // Large Leaderboard: 970x90
        break;
      case 10:
        rendered = web728x90; // Leaderboard: 728x90
        break;
      case 11:
        rendered = web715x130; // Leaderboard: 715x130
        break;
      case 12:
        rendered = web468x60; // Banner: 468x60
        break;
      case 13:
        rendered = web1208x744; // XL Banner: 1208x744
        break;
      case 14:
        rendered = web1272x792; // XL Banner: 1272x792
        break;
      case 15:
        rendered = web1500x500; // XL Banner: 1500x500
        break;
      case 16:
        rendered = web1880x360; // XL Banner: 1880x360
        break;
      case 17:
        rendered = web2400x1350; // XL Banner: 2400x1350
        break;
      default:
        break;
    }
    return rendered;
  };

  return (
    <div className='max-w-[85rem] p-8 space-y-5'>
      <h1 className='text-2xl font-bold'>Banners</h1>
      <div className='grid grid-cols-9 gap-10'>
        <div className='col-span-5 space-y-12'>
          <p className='text-sm font-normal '>
            We’ve created some email banner ads to help you to promote your
            cashback loyalty program through single or multi-email campaigns.
          </p>
          <div className='flex flex-wrap gap-5 w-full'>
            <Button
              className={` ${
                activeTab === 'email'
                  ? 'bg-[#00C389] text-white'
                  : 'text-black bg-white'
              }  font-semibold text-lg py-4 grow`}
              onClick={() => setActiveTab('email')}
            >
              Email Ad Banners
            </Button>
            <Button
              className={` ${
                activeTab === 'web'
                  ? 'bg-[#00C389] text-white'
                  : 'text-black bg-white'
              } font-semibold text-lg py-4 grow`}
              onClick={() => setActiveTab('web')}
            >
              Web Ad Banners
            </Button>
          </div>
          <hr className='border-t border-[#C3C3C3]' />
          {activeTab === 'email' ? (
            <div className='flex gap-3 flex-wrap'>
              <Button
                className={` ${
                  firstTabButtons === 1 ? 'bg-[#ECECEC]' : ' bg-white'
                } text-black font-semibold text-sm`}
                onClick={() => setFirstTabButtons(1)}
              >
                Email Banner: 330x228
              </Button>
              <Button
                className={` ${
                  firstTabButtons === 2 ? 'bg-[#ECECEC]' : ' bg-white'
                } text-black font-semibold text-sm`}
                onClick={() => setFirstTabButtons(2)}
              >
                Email Banner: 600x288
              </Button>
            </div>
          ) : (
            <div className='flex gap-3 flex-wrap'>
              {webAdSizes.map(item => (
                <Button
                  key={item.id}
                  onClick={() => setActiveId(item.id)}
                  className={` ${
                    activeId === item.id ? 'bg-[#ECECEC]' : ' bg-white'
                  } text-black font-semibold text-sm`}
                >
                  {item.text}
                </Button>
              ))}
            </div>
          )}
        </div>
        <div className='border border-[#A6A6A6] col-span-4 rounded-md space-y-4 py-7 px-4 overflow-y-auto h-[50rem] w-fit'>
          <p className='text-2xl font-bold'>Examples</p>
          <hr className='border-t border-[#C3C3C3]' />
          {activeTab === 'email' ? (
            <>
              {firstTabButtons === 1
                ? emailBannerImages1.map((imagePath, index) => (
                    <div key={index} className='space-y-2'>
                      <img
                        src={imagePath}
                        alt={`banner-${index}`}
                        className='w-[20.6rem] h-[14.25rem] object-cover'
                      />
                      <Button
                        onClick={() => downloadImage(imagePath)}
                        className='border border-[#B1B1B1] text-base font-medium mx-auto h-11'
                      >
                        Download
                      </Button>
                    </div>
                  ))
                : emailBannerImages2.map((imagePath, index) => (
                    <div key={index} className='space-y-2'>
                      <img
                        src={imagePath}
                        alt={`banner-${index}`}
                        className={`w-[20.6rem] h-[10.25rem] `}
                      />
                      <Button
                        onClick={() => downloadImage(imagePath)}
                        className='border border-[#B1B1B1] text-base font-medium mx-auto h-11'
                      >
                        Download
                      </Button>
                    </div>
                  ))}
            </>
          ) : (
            <div className='flex justify-center flex-wrap gap-4'>
              {renderedPhotos().map(item => (
                <>
                  <div className='space-y-2 flex flex-col'>
                    <img
                      src={item}
                      alt={'banner'}
                      className='w-full h-full object-cover'
                    />
                    <Button
                      onClick={() => downloadImage(item)}
                      className='border border-[#B1B1B1] text-base font-medium mx-auto h-11'
                    >
                      Download
                    </Button>
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Banners;
