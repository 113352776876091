import React from 'react';
import { images } from '../../constants/images';

function PrintMeterial() {
  return (
    <section className='px-6 mb-16'>
      <div className='mt-5 mb-[10rem]'>
        <h3 className='text-2xl font-bold mb-[1.15rem]'>Print Material</h3>
      </div>

      <div className='mx-auto max-w-[35rem]'>
        <img src={images.comingSoon} alt='Coming soon' />
      </div>
    </section>
  );
}

export default PrintMeterial;
