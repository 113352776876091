import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { User } from '../../utils/type';
import { UpdateBrandInfo } from '../../features/partnerSlice';
import RequiredAsterisk from '../../components/RequiredAsterisk';
import Button from '../../components/button/Button';
import { Toaster } from 'react-hot-toast';
interface BrandingFormData {
  orgDisplayName: string;
  partnerId: string | null;
  primaryColor: string;
  secondaryColor: string;
  websiteLink?: string | null;
  facebookLink?: string | null;
  instagramLink?: string | null;
  other?: string | null;
  logo: FileList;
  logoMobile?: FileList;
  logoMonochrome?: FileList;
  icon?: FileList;
  tmCertificate?: FileList;
  additionalDocument?: FileList;
  einNumber: string;
  accountHolderName: string;
  bankName: string;
  routingNumber: string;
  accountNumber: string;
  swiftCode?: string | null;
  email?: string | null;
  phone?: string | null;
  iban?: string | null;
  tin?: string | null;
  socialMedia?: string;
  brandInfo?: string;
}

const OrgData = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [partnerId, setPartnerId] = useState<string | null>(null);
  const [logoFileName, setLogoFileName] = useState('');
  const [primaryColorValue, setPrimaryColorValue] = useState('#1133BB');
  const [logoMobileFileName, setMobileLogoFileName] = useState('');
  const [logoMonochromeFileName, setMonochromeLogoFileName] = useState('');
  const [tmCertificateFileName, setTMCertificateFileName] = useState('');
  const [secondaryColorValue, setSecondaryColorValue] = useState('#00C389');
  const [additionalDocumentFileName, setAdditionalDocumentFileName] =
    useState('');
  const [isAdditionalDocumentUpdated, setIsAdditionalDocumentUpdated] =
    useState(false);
  const [isLogoUpdated, setIsLogoUpdated] = useState(false);
  const [isLogoMobileUpdated, setIsLogoMobileUpdated] = useState(false);
  const [isLogoMonochromeUpdated, setIsLogoMonochromeUpdated] = useState(false);
  const [isTMCertificateUpdated, setIsTMCertificateUpdated] = useState(false);

  const extractFileName = (url: any) => {
    return url.split('/').pop();
  };

  const handlePrimaryColorChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPrimaryColorValue(event.target.value);
  };

  const BrandInfoState = useSelector(
    (state: RootState) => state.partner.brandInfo
  );
  const dispatch = useAppDispatch();

  const userDetails = localStorage.getItem('userDetails');
  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<BrandingFormData>({
    defaultValues: {
      partnerId,
    },
  });

  useEffect(() => {
    if (BrandInfoState) {
      // Set values for brand info

      // setValue('primaryColor', BrandInfoState.primaryColor);
      setValue('orgDisplayName', BrandInfoState.orgDisplayName);
      setValue('einNumber', BrandInfoState.einNumber);
      // setValue('secondaryColor', BrandInfoState.secondaryColor);
      setValue('websiteLink', BrandInfoState.websiteLink || '');
      setValue('facebookLink', BrandInfoState.facebookLink || '');
      setValue('instagramLink', BrandInfoState.instagramLink || '');
      setValue('other', BrandInfoState.other || '');

      // Set file upload fields with the filename extracted from URL
      if (BrandInfoState.primaryColor)
        setPrimaryColorValue(BrandInfoState.primaryColor);
      if (BrandInfoState.secondaryColor)
        setSecondaryColorValue(BrandInfoState.secondaryColor);
      if (BrandInfoState.logo) {
        setLogoFileName(extractFileName(BrandInfoState.logo));
        setValue('logo', extractFileName(BrandInfoState.logo));
      }
      if (BrandInfoState.logoMobile) {
        setMobileLogoFileName(extractFileName(BrandInfoState.logoMobile));
        setValue('logoMobile', extractFileName(BrandInfoState.logoMobile));
      }
      if (BrandInfoState.logoMonochrome) {
        setMonochromeLogoFileName(
          extractFileName(BrandInfoState.logoMonochrome)
        );
        setValue(
          'logoMonochrome',
          extractFileName(BrandInfoState.logoMonochrome)
        );
      }
      if (BrandInfoState.tmCertificate) {
        setTMCertificateFileName(extractFileName(BrandInfoState.tmCertificate));
        setValue(
          'tmCertificate',
          extractFileName(BrandInfoState.tmCertificate)
        );
      }
      if (BrandInfoState.additionalDocument) {
        setAdditionalDocumentFileName(
          extractFileName(BrandInfoState.additionalDocument)
        );
        setValue(
          'additionalDocument',
          extractFileName(BrandInfoState.additionalDocument)
        );
      }
    }
  }, [BrandInfoState, setValue]);
  useEffect(() => {
    if (userDetails) {
      const userObject: User = JSON.parse(userDetails);
      setPartnerId(userObject.partnerId);
    }
  }, [userDetails]);

  const handleSecondaryColorChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSecondaryColorValue(event.target.value);
  };

  const handleLogoFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setLogoFileName(event.target.files[0].name);
      setIsLogoUpdated(true);
    }
  };

  const handleMobileLogoFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setMobileLogoFileName(event.target.files[0].name);
      setIsLogoMobileUpdated(true);
    }
  };

  const handleMonochromeLogoFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setMonochromeLogoFileName(event.target.files[0].name);
      setIsLogoMonochromeUpdated(true);
    }
  };

  const handleTMCertificateFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setTMCertificateFileName(event.target.files[0].name);
      setIsTMCertificateUpdated(true);
    }
  };

  const handleAdditionalDocumentFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setAdditionalDocumentFileName(event.target.files[0].name);
      setIsAdditionalDocumentUpdated(true);
    }
  };

  const onsubmit = (data: BrandingFormData) => {
    if (partnerId) {
      const brandFormData = new FormData();
      brandFormData.append('partnerId', partnerId);
      brandFormData.append('orgDisplayName', data.orgDisplayName || '');
      brandFormData.append('einNumber', data.einNumber);
      brandFormData.append('primaryColor', primaryColorValue);
      brandFormData.append('secondaryColor', secondaryColorValue);
      brandFormData.append('websiteLink', data.websiteLink || '');
      brandFormData.append('facebookLink', data.facebookLink || '');
      brandFormData.append('instagramLink', data.instagramLink || '');
      brandFormData.append('other', data.other || '');
      brandFormData.append('brandInfo', 'completed');
      for (const key in data) {
        if (key === 'logo') {
          if (isLogoUpdated && data.logo) {
            brandFormData.append('logo', data[key][0]);
          }
        }
        if (key === 'logoMobile') {
          if (isLogoMobileUpdated && data.logoMobile) {
            brandFormData.append('logoMobile', data.logoMobile[0]);
          }
        }
        if (key === 'logoMonochrome') {
          if (isLogoMonochromeUpdated && data.logoMonochrome) {
            brandFormData.append('logoMonochrome', data.logoMonochrome[0]);
          }
        }
        if (key === 'tmCertificate') {
          if (isTMCertificateUpdated && data.tmCertificate) {
            brandFormData.append('tmCertificate', data.tmCertificate[0]);
          }
        }
        if (key === 'additionalDocument') {
          if (isAdditionalDocumentUpdated && data.additionalDocument) {
            console.log(251, data.additionalDocument[0]);
            brandFormData.append(
              'additionalDocument',
              data.additionalDocument[0]
            );
          }
        }
      }
      dispatch(UpdateBrandInfo(brandFormData));
      setIsEditing(false);
    }
  };

  return (
    <>
      <Toaster />
      <form
        onSubmit={handleSubmit(onsubmit)}
        className='p-6 bg-[#ffffff] shadow rounded-lg'
      >
        <div className='flex justify-between items-center mb-6'>
          <h2 className='text-xl font-semibold'>Branding</h2>
          <button
            type='button'
            onClick={handleEditToggle}
            className='text-[#d50000] text-[1.25rem] pr-1 font-semibold'
          >
            {isEditing ? 'Cancel' : 'Edit'}
          </button>
        </div>

        <div
          className={`mb-[10rem] ${
            !isEditing ? ' bg-opacity-90 pointer-events-none' : ''
          }`}
          style={{
            backgroundColor: !isEditing
              ? 'rgba(255, 255, 255, 0.5)'
              : 'transparent', // or any other background color
          }}
        >
          <div className=' justify-center max-w-[full] mx-auto shadow-md rounded-lg'>
            <div className='bg-white shadow-md rounded-lg w-full'>
              <div className='mb-8'>
                <div className='mb-6 pb-4'>
                  <h2 className='text-white bg-primary p-4 px-8 rounded-t-lg text-lg font-semibold'>
                    Branding
                  </h2>
                  <div className='mb-0 mt-4 px-4 md:px-8 md:py-4'>
                    <p className='text-base mb-8 text-[#343434] '>
                      Enter your organization&apos;s name to be displayed on the
                      app and website. Upload a high-resolution logo in either
                      JPEG or PNG format. Specify your brand&apos;s primary and
                      secondary colors using hex codes.
                    </p>
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4 pb-4 mb-6'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Organization Display Name{' '}
                          <RequiredAsterisk isEditing={isEditing} />
                        </label>
                        <input
                          type='text'
                          id='orgDisplayName'
                          className='register-input w-full'
                          {...register('orgDisplayName', {
                            required: 'Organization display name is required',
                          })}
                        />

                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.orgDisplayName?.message}
                        </p>
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Upload Logo <RequiredAsterisk isEditing={isEditing} />
                        </label>
                        <div className='flex items-center w-full'>
                          {/* Input field to display selected file name */}
                          <input
                            type='text'
                            value={logoFileName}
                            readOnly
                            placeholder='No file selected'
                            className='register-input w-4/5 rounded-r-[0px]'
                          />

                          {/* Hidden file input */}
                          <input
                            type='file'
                            id='logo'
                            accept='image/png, image/jpeg'
                            className='hidden'
                            {...register('logo', {
                              required: !logoFileName
                                ? 'Logo is required'
                                : false,

                              onChange: handleLogoFileChange,
                            })}
                          />

                          {/* Label styled as a button */}
                          <label
                            htmlFor='logo'
                            className='w-1/5 text-[0.75rem] cursor-pointer py-2 md:py-4 px-1 bg-[#aeaeae]  text-white text-center rounded-r-[4px] hover:bg-[#aeaeae]'
                          >
                            Choose File
                          </label>
                        </div>
                        <p className='text-[0.75rem] mt-1 text-[#878787] '>
                          Upload your organization&apos;s logo to be featured in
                          the app.
                        </p>
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.logo?.message}
                        </p>
                      </div>
                    </div>
                    {/* Additional logo */}
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Upload Mobile Logo or Favicon
                        </label>
                        <div className='flex items-center w-full'>
                          {/* Input field to display selected file name */}
                          <input
                            type='text'
                            value={logoMobileFileName}
                            readOnly
                            placeholder='No file selected'
                            className='register-input w-4/5 rounded-r-[0px]'
                          />

                          {/* Hidden file input */}
                          <input
                            type='file'
                            id='logoMobile'
                            className='hidden'
                            {...register('logoMobile', {
                              onChange: handleMobileLogoFileChange,
                            })}
                          />

                          {/* Label styled as a button */}
                          <label
                            htmlFor='logoMobile'
                            className='w-1/5 text-[0.75rem] cursor-pointer py-2 md:py-4 px-1 bg-[#aeaeae]  text-white text-center rounded-r-[4px] hover:bg-[#aeaeae]'
                          >
                            Choose File
                          </label>
                        </div>
                        <p className='text-[0.75rem] mt-1 text-[#878787] '>
                          Upload a logo icon for optimal display on mobile
                          screens and browser tabs (optional)
                        </p>
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.logoMobile?.message}
                        </p>
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Upload Monochrome Logo
                        </label>
                        <div className='flex items-center w-full'>
                          {/* Input field to display selected file name */}
                          <input
                            type='text'
                            value={logoMonochromeFileName}
                            readOnly
                            placeholder='No file selected'
                            className='register-input w-4/5 rounded-r-[0px]'
                          />

                          {/* Hidden file input */}
                          <input
                            type='file'
                            id='logoMonochrome'
                            className='hidden'
                            {...register('logoMonochrome', {
                              onChange: handleMonochromeLogoFileChange,
                            })}
                          />

                          {/* Label styled as a button */}
                          <label
                            htmlFor='logoMonochrome'
                            className='w-1/5 text-[0.75rem] cursor-pointer py-2 md:py-4 px-1 bg-[#aeaeae]  text-white text-center rounded-r-[4px] hover:bg-[#aeaeae]'
                          >
                            Choose File
                          </label>
                        </div>
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.logoMonochrome?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Row 2 */}
                  <div className='mb-0 mt-4 px-4 md:px-8 md:py-4'>
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Primary Color (Hex){' '}
                          <RequiredAsterisk isEditing={isEditing} />
                        </label>
                        <div className='flex items-center w-full space-x-2'>
                          {/* Text Input */}
                          <input
                            type='text'
                            id='primaryColor'
                            className='register-input w-full border border-gray-300 rounded-md px-3 py-2'
                            value={primaryColorValue}
                            {...register('primaryColor')}
                            readOnly
                          />

                          {/* Color Picker */}
                          <input
                            type='color'
                            value={primaryColorValue}
                            id='color'
                            className='w-1/5 h-10 cursor-pointer '
                            onChange={handlePrimaryColorChange}
                          />
                        </div>
                        <p className='text-[0.75rem] mt-1 text-[#878787] '>
                          Add your primary and secondary colors to be used
                          throughout your loyalty program.
                        </p>
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.primaryColor?.message}
                        </p>
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Secondary Color (Hex)
                          <RequiredAsterisk isEditing={isEditing} />
                        </label>
                        <div className='flex items-center w-full space-x-2'>
                          {/* Text Input */}
                          <input
                            type='text'
                            id='secondaryColor'
                            className='register-input w-full border border-gray-300 rounded-md px-3 py-2'
                            value={secondaryColorValue}
                            {...register('secondaryColor')}
                            readOnly
                          />

                          {/* Color Picker */}
                          <input
                            type='color'
                            value={secondaryColorValue}
                            id='color'
                            className='w-1/5 h-10  cursor-pointer'
                            onChange={handleSecondaryColorChange}
                          />
                        </div>
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.secondaryColor?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <BrandingInformation brandInfoPage={true} isEditing={isEditing} /> */}
          <div className='justify-center max-w-[full] mx-auto shadow-md rounded-lg'>
            <div className='bg-white shadow-md rounded-lg w-full'>
              <div className='mb-8'>
                <div className='mb-6 pb-4'>
                  <h2 className='text-white bg-primary p-4 px-8 rounded-t-lg text-lg font-semibold'>
                    Social Media Info
                  </h2>
                  <div className='mb-0 mt-4 px-4 md:px-8 md:py-4'>
                    <p className='text-base mb-8 text-[#878787]'>
                      Enter your organization&apos;s website and social media
                      links here.
                    </p>
                    <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[2px] mb-[5px]'>
                      {errors.socialMedia?.message}
                    </p>
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Website <RequiredAsterisk isEditing={isEditing} />
                        </label>
                        <input
                          type='text'
                          id='websiteLink'
                          className='register-input w-full'
                          {...register('websiteLink', {
                            required: 'Website Link is required',
                          })}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.websiteLink?.message}
                        </p>
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Facebook
                        </label>
                        <input
                          type='text'
                          id='facebookLink'
                          className='register-input w-full'
                          {...register('facebookLink')}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.facebookLink?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Row 2 */}
                  <div className='mb-0 mt-4 px-4 md:px-8 md:py-4'>
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Instagram
                        </label>
                        <input
                          type='text'
                          id='instagramLink'
                          className='register-input w-full'
                          {...register('instagramLink')}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.instagramLink?.message}
                        </p>
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Other
                        </label>
                        <input
                          type='text'
                          id='other'
                          className='register-input w-full'
                          {...register('other')}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.other?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=' justify-center max-w-[full] mx-auto shadow-md rounded-lg'>
            <div className='bg-white shadow-md rounded-lg w-full'>
              <div className='mb-8'>
                <div className='mb-6  pb-4'>
                  <h2 className='text-white bg-primary p-4 px-8 rounded-t-lg text-lg font-semibold'>
                    Additional Program Info
                  </h2>
                  <div className='mb-0 mt-4 px-4 md:px-8 md:py-4'>
                    <p className='text-base mb-8 text-[#878787] '>
                      Enter your organization&apos;s TIN/EIN (Taxpayer /
                      Employer Identification Number). Upload your
                      organization&apos;s Company Registration / Incorporation
                      Certificate and Trademark Certificate(s), if any.
                    </p>

                    {/* row 1 */}
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4 md:mb-6 mb-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Trademark Certificate
                        </label>
                        <div className='flex items-center w-full'>
                          {/* Input field to display selected file name */}
                          <input
                            type='text'
                            value={tmCertificateFileName}
                            readOnly
                            placeholder='No file selected'
                            className='register-input w-4/5 rounded-r-[0px]'
                          />

                          {/* Hidden file input */}
                          <input
                            type='file'
                            id='tmCertificate'
                            className='hidden'
                            {...register('tmCertificate', {
                              onChange: handleTMCertificateFileChange,
                            })}
                          />

                          {/* Label styled as a button */}
                          <label
                            htmlFor='tmCertificate'
                            className='w-1/5 text-[0.75rem] cursor-pointer py-2 md:py-4 px-1 bg-[#aeaeae]  text-white text-center rounded-r-[4px] hover:bg-[#aeaeae]'
                          >
                            Choose File
                          </label>
                        </div>
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.tmCertificate?.message}
                        </p>
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          TIN/EIN Number
                          <RequiredAsterisk isEditing={isEditing} />
                        </label>
                        <input
                          type='text'
                          id='einNumber'
                          className='register-input w-full'
                          {...register('einNumber', {
                            required: 'TIN/EIN Number is required',
                          })}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.einNumber?.message}
                        </p>
                      </div>
                    </div>

                    {/* Additional Info - row 2 */}
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Additional Documents, if any
                        </label>
                        <div className='flex items-center w-full'>
                          {/* Input field to display selected file name */}
                          <input
                            type='text'
                            value={additionalDocumentFileName}
                            readOnly
                            placeholder='No file selected'
                            className='register-input w-4/5 rounded-r-[0px]'
                          />

                          {/* Hidden file input */}
                          <input
                            type='file'
                            id='additionalDocument'
                            className='hidden'
                            {...register('additionalDocument', {
                              onChange: handleAdditionalDocumentFileChange,
                            })}
                          />

                          {/* Label styled as a button */}
                          <label
                            htmlFor='additionalDocument'
                            className='w-1/5 text-[0.75rem] cursor-pointer py-2 md:py-4 px-1 bg-[#aeaeae]  text-white text-center rounded-r-[4px] hover:bg-[#aeaeae]'
                          >
                            Choose File
                          </label>
                        </div>
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.additionalDocument?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full flex justify-end gap-4'>
            {isEditing && (
              <Button
                type='submit'
                className={`w-full text-sm px-5 text-white bg-primary border-primary min-w-[7rem] py-2 md:w-[7.5rem] rounded-[10px] font-semibold`}
              >
                Save Changes
              </Button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default OrgData;
