import { useEffect, useState } from 'react';
import LineChartComp, {
  FilterByDateWithFlag,
} from '../../components/lineChart/LineChart';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import Button from '../../components/button/Button';
import {
  GetGraphTransactionsCounts,
  setGraphTransactionsCounts,
} from '../../features/partnerSlice';
import {
  GetGraphTransactionValues,
  setGraphTransactionValues,
} from '../../features/reportingSlice';

const TotalTransactions = () => {
  const [activeTab, setActiveTab] = useState<'1' | '2' | '3'>('1');
  const [customDate, setCustomDate] = useState<FilterByDateWithFlag>({
    startDate: '',
    endDate: '',
    typeName: '',
  });

  const { dashboardInfo, graphTransactionCounts, loadingStates } =
    useAppSelector((state: RootState) => state.partner);
  const { graphTransactionValues } = useAppSelector(
    (state: RootState) => state.reporting
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { typeName, ...date } = customDate;

    if (typeName === 'TransactionCount') {
      dispatch(GetGraphTransactionsCounts(date));
    } else if (!graphTransactionCounts && activeTab === '1') {
      dispatch(GetGraphTransactionsCounts());
    }
    if (typeName === 'TransactionValue') {
      dispatch(GetGraphTransactionValues(date));
    } else if (!graphTransactionValues && activeTab === '2') {
      dispatch(GetGraphTransactionValues());
    }

    if (typeName) {
      setCustomDate(prev => ({ ...prev, typeName: '' }));
    }
  }, [
    activeTab,
    customDate,
    dispatch,
    graphTransactionCounts,
    graphTransactionValues,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setGraphTransactionsCounts(null));
      dispatch(setGraphTransactionValues(null));
    };
  }, [dispatch]);

  return (
    <div className='space-y-10'>
      {/* Toggle Button */}
      <div className='space-y-6 mt-6'>
        <h1 className=' font-bold text-2xl'>Total Transactions Count</h1>
        <p className='text-xl font-semibold'>
          {dashboardInfo?.txnCount ?? '0'}
        </p>
      </div>
      <div className='flex gap-10'>
        <Button
          className={` ${
            activeTab === '1'
              ? 'bg-[#00C389] text-white'
              : 'text-black bg-white'
          }    w-full`}
          onClick={() => setActiveTab('1')}
        >
          Transactions Count
        </Button>
        <Button
          className={` ${
            activeTab === '2'
              ? 'bg-[#00C389] text-white'
              : 'text-black bg-white'
          }    w-full`}
          onClick={() => setActiveTab('2')}
        >
          Transactions Value
        </Button>
      </div>
      {/* Display Charts or List View */}
      <div className='grid grid-cols-1 '>
        {activeTab === '1' && (
          <LineChartComp
            data={graphTransactionCounts}
            dataKey='txnCount'
            isFiltering={true}
            name='Transaction Count'
            description='Monthly count of transactions completed by active customers'
            chartName='TransactionCount'
            setCustomDate={setCustomDate}
            isLoading={loadingStates['GetGraphTransactionsCounts']}
          />
        )}
        {activeTab === '2' && (
          <LineChartComp
            data={graphTransactionValues}
            isFiltering={true}
            name='Transaction Value'
            dollar={true}
            dataKey='valTransactions'
            description='Monthly value of transactions completed by active customers'
            chartName='TransactionValue'
            setCustomDate={setCustomDate}
            isLoading={loadingStates['GetGraphTransactionValues']}
          />
        )}
      </div>
    </div>
  );
};

export default TotalTransactions;
