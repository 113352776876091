import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { IoMdLogOut } from 'react-icons/io';
import { useAppDispatch } from '../../app/hooks';
import { logoutAndRedirect } from '../../features/authSlice';
import { useNavigate } from 'react-router';
interface Props {
  toggleDrawer: () => void;
  isDrawerOpen: boolean;
}

const Navbar = ({ isDrawerOpen, toggleDrawer }: Props) => {
  const userDetails = localStorage.getItem('userDetails');
  let user;
  const userStatus = useSelector((state: RootState) => state.auth.status);
  if (userDetails) {
    user = JSON.parse(userDetails);
  }
  const [statusText, setStatusText] = useState('');
  const [statusColor, setStatusColor] = useState('');
  const [hovered, setHovered] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logoutAndRedirect(navigate));
  };

  useEffect(() => {
    // Map status to corresponding text and color classes
    const statusMapping = {
      active: { text: 'Active', color: 'text-[#018d31]' },
      inactive: { text: 'Update Brand Info', color: 'text-red-400' },
      inreview: { text: 'In Review', color: 'text-yellow-400' },
      suspended: { text: 'Suspended', color: 'text-red-400' },
      onhold: { text: 'On Hold', color: 'text-orange-400' },
      null: { text: '', color: '#000000' },
    };

    // Get the appropriate status text and color based on userStatus
    const { text, color } = statusMapping[userStatus] || {
      text: 'Unknown',
      color: 'text-gray-400',
    };

    setStatusText(text);
    setStatusColor(color);
  }, [userStatus]);

  return (
    <>
      <nav
        className={`grid md:grid-cols-2 place-content-center  h-[100px] w-full bg-primaryBg-50  ${
          isDrawerOpen ? 'lg:pr-7 pr-3 md:pr-5' : 'lg:px-7 px-3 md:px-5'
        } `}
      >
        {/*menu-icon div*/}
        <div className='flex items-center justify-between'>
          <button
            onClick={toggleDrawer}
            className={`${
              isDrawerOpen ? 'hidden' : 'block'
            } text-gray-900 focus:outline-none pr-7`}
          >
            <svg
              className='w-6 h-6'
              fill='#333399'
              stroke='#333399'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M4 6h16M4 12h16M4 18h16'
              ></path>
            </svg>
          </button>

          <div className='hidden md:block w-full'>
            <div className='flex flex-row md:gap-[4rem] xmd:gap-[7rem] whitespace-nowrap justify-between'>
              <h4 className='md:text-[0.75rem] xmd:text-[1.25rem] pl-4 font-medium'>
                {user?.partner?.name}
              </h4>
              <h4 className='md:text-[0.75rem] xmd:text-[1.25rem]'>
                Status:{' '}
                <span className={`${statusColor} mt-[1px] font-semibold`}>
                  {statusText}
                </span>
              </h4>
            </div>
          </div>
          <div className='block md:hidden'>
            <div className='flex flex-row gap-10 md:gap-[7rem]'>
              <h4 className='md:text-[0.75rem] xmd:text-[1.25rem]'>
                {user?.partner?.name}
              </h4>
              <h4 className='md:text-[0.75rem] xmd:text-[1.25rem]'>
                Status:{' '}
                <span className={`${statusColor} mt-[1px] font-semibold`}>
                  {statusText}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className='hidden md:flex space-x-1 justify-end items-center'>
          <div className='hidden md:block whitespace-nowrap text-primary'>
            <p className='font-semibold text-[1.25rem] pt-2 '>
              {user?.firstName} {user?.lastName}
            </p>
          </div>
          <div
            className='pl-4 pt-2 flex items-center cursor-pointer relative'
            onClick={handleLogout}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {/* Logout Icon */}
            <IoMdLogOut className='text-primary text-xl pointer' />
            {/* Hover Text */}
            <span
              className={`absolute top-[-1rem] left-[-0.5rem] bg-white text-black text-sm px-2 py-1 rounded shadow-lg transition-opacity duration-300 ${
                hovered ? 'opacity-100' : 'opacity-0'
              }`}
            >
              Logout
            </span>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
