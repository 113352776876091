import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import RequiredAsterisk from '../../components/RequiredAsterisk';
import Button from '../../components/button/Button';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { User } from '../../utils/type';
import { UpdateBankInfo } from '../../features/partnerSlice';
import { useAppDispatch } from '../../app/hooks';
import { Toaster } from 'react-hot-toast';

interface PayoutFormData {
  partnerId?: string | null;
  reEnterAccountNumber: string;
  accountHolderName: string;
  bankName: string;
  routingNumber: string;
  accountNumber: string;
  swiftCode?: string | null;
  email?: string | null;
  phone?: string | null;
  iban?: string | null;
  tin?: string | null;
}
const PayoutAccount = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [partnerId, setPartnerId] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  const userDetails = localStorage.getItem('userDetails');
  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<PayoutFormData>({
    defaultValues: {
      partnerId,
    },
  });
  const BankInfoState = useSelector(
    (state: RootState) => state.partner.paymentInfo
  );

  useEffect(() => {
    if (BankInfoState) {
      setValue('accountHolderName', BankInfoState.accountHolderName || '');
      setValue('bankName', BankInfoState.bankName || '');
      setValue('routingNumber', BankInfoState.routingNumber || '');
      setValue('accountNumber', BankInfoState.accountNumber || '');
      if (BankInfoState.accountNumber) {
        setValue('reEnterAccountNumber', BankInfoState.accountNumber || '');
      }
      setValue('swiftCode', BankInfoState.swiftCode || '');
      setValue('email', BankInfoState.email || '');
      setValue('phone', BankInfoState.phone || '');
      setValue('tin', BankInfoState.tin || '');
      setValue('iban', BankInfoState.iban || '');
    }
  }, [BankInfoState, setValue]);
  useEffect(() => {
    if (userDetails) {
      const userObject: User = JSON.parse(userDetails);
      setPartnerId(userObject.partnerId);
    }
  }, [userDetails]);

  const onsubmit = (data: PayoutFormData) => {
    if (partnerId) {
      const bankFormData = new FormData();
      bankFormData.append('partnerId', partnerId);
      bankFormData.append('accountHolderName', data.accountHolderName);
      bankFormData.append('bankName', data.bankName);
      bankFormData.append('routingNumber', data.routingNumber);
      bankFormData.append('accountNumber', data.accountNumber);
      bankFormData.append('swiftCode', data.swiftCode || '');
      bankFormData.append('email', data.email || '');
      bankFormData.append('phone', data.phone || '');
      bankFormData.append('tin', data.tin || '');
      bankFormData.append('iban', data.iban || '');
      dispatch(UpdateBankInfo(partnerId, bankFormData));
      setIsEditing(false);
    }
  };
  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit(onsubmit)}>
        <div className='flex justify-between items-center mb-6'>
          <h2 className='text-xl font-semibold mt-2'>Payout Account</h2>
          <button
            type='button'
            onClick={handleEditToggle}
            className='text-[#d50000] text-[1.25rem] pr-1 font-semibold'
          >
            {isEditing ? 'Cancel' : 'Edit'}
          </button>
        </div>
        <div
          className={`mb-[10rem] ${
            !isEditing ? ' bg-opacity-90 pointer-events-none' : ''
          }`}
          style={{
            backgroundColor: !isEditing
              ? 'rgba(255, 255, 255, 0.5)'
              : 'transparent', // or any other background color
          }}
        >
          {/* <BrandingInformation payoutAccountPage={true} isEditing={isEditing} /> */}
          <div className='justify-center max-w-[full]  rounded-lg mt-8'>
            <div className='bg-white rounded-lg w-full'>
              <div className='mb-8'>
                <div className='mb-6 pb-4'>
                  {/* <h2 className='text-white bg-primary p-4 px-8 rounded-t-lg text-lg font-semibold'>
                    Payout Account
                  </h2> */}
                  <div className='mb-0 mt-4  md:py-4'>
                    <p className='text-base mb-8 text-[#343434]'>
                      Enter your bank account details to receive payouts.
                    </p>
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4 py-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Account Holder Name
                          <RequiredAsterisk isEditing={isEditing} />
                        </label>
                        <input
                          type='text'
                          id='accountHolderName'
                          className='register-input w-full'
                          {...register(
                            'accountHolderName',

                            {
                              required: 'Account Holder Name is required',
                            }
                          )}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.accountHolderName?.message}
                        </p>
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Sales Tax Number
                        </label>
                        <input
                          type='text'
                          id='tin'
                          className='register-input w-full'
                          {...register('tin')}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.tin?.message}
                        </p>
                      </div>
                    </div>

                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4 mt-4 py-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Bank Name
                          <RequiredAsterisk isEditing={isEditing} />
                        </label>
                        <input
                          type='text'
                          id='bankName'
                          className='register-input w-full'
                          {...register('bankName', {
                            required: 'Bank Name is required',
                          })}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.bankName?.message}
                        </p>
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Routing Number
                          <RequiredAsterisk isEditing={isEditing} />
                        </label>
                        <input
                          type='text'
                          id='routingNumber'
                          className='register-input w-full'
                          {...register('routingNumber', {
                            required: 'Routing Number is required',
                          })}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.routingNumber?.message}
                        </p>
                      </div>
                    </div>

                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4 mt-4 py-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Account Number
                          <RequiredAsterisk isEditing={isEditing} />
                        </label>
                        <input
                          type='text'
                          id='accountNumber'
                          className='register-input w-full'
                          {...register('accountNumber', {
                            required: 'Account Number is required',
                          })}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.accountNumber?.message}
                        </p>
                      </div>

                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Re-enter Account Number
                          <RequiredAsterisk isEditing={isEditing} />
                        </label>
                        <input
                          type='text'
                          id='reEnterAccountNumber'
                          className='register-input w-full'
                          {...register('reEnterAccountNumber', {
                            required:
                              'Re-entering your account number is required',

                            validate: value =>
                              value === getValues('accountNumber') ||
                              'Account numbers do not match',
                          })}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.reEnterAccountNumber?.message}
                        </p>
                      </div>
                    </div>

                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4 mt-4 py-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Swift Code
                        </label>
                        <input
                          type='text'
                          id='swiftCode'
                          className='register-input w-full'
                          {...register('swiftCode')}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.swiftCode?.message}
                        </p>
                      </div>

                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          IBAN
                        </label>
                        <input
                          type='text'
                          id='iban'
                          className='register-input w-full'
                          {...register('iban')}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.iban?.message}
                        </p>
                      </div>
                    </div>

                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4 mt-4 py-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Registered Email
                        </label>
                        <input
                          type='email'
                          id='email'
                          className='register-input w-full'
                          {...register('email')}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.email?.message}
                        </p>
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Registered Contact Number
                        </label>
                        <input
                          type='text'
                          id='phone'
                          className='register-input w-full'
                          {...register('phone', {
                            pattern: {
                              value:
                                /^\+?[0-9]{1,4}[\s-]?\(?[0-9]{1,5}\)?[\s-]?[0-9]{1,9}[\s-]?[0-9]{1,9}$/,
                              message: 'Invalid phone number format',
                            },
                          })}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.phone?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full flex justify-end gap-4'>
            {isEditing && (
              <Button
                type='submit'
                className={`w-full text-sm px-5 text-white bg-primary border-primary min-w-[7rem] py-2 md:w-[7.5rem] rounded-[10px] font-semibold`}
              >
                Save Changes
              </Button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};
export default PayoutAccount;
