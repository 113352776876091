import axios from 'axios';
import { logout } from '../features/authSlice';
import { Dispatch } from '@reduxjs/toolkit';

export const handleAxiosError = (error: unknown, dispatch: Dispatch) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 401) {
      console.log('Unauthorized - Logging out');
      dispatch(logout()); // Dispatch the logout action
    } else {
      console.error('Error response:', error.response?.data);
    }
  } else {
    console.error('Unexpected error:', error);
  }
};
