import React, { ChangeEvent, useEffect, useReducer, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { usersConfig, usersData } from '../../assets/dummy';
import Button from '../../components/button/Button';
import Modal from '../../components/modal/Modal';
import RedStar from '../../components/RedStar';
import Table from '../../components/table/Table';
import useShowModal from '../../hooks/useShowModal';

type State = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isAdmin: boolean;
  dateCreated: string;
};

type Action =
  | { type: 'SET_FIELD'; fieldName: string; payload: string }
  | { type: 'RESET' }
  | { type: 'SET_USER'; payload: Partial<State> };

const initialState: State = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  isAdmin: false,
  dateCreated: '',
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.fieldName]: action.payload };
    case 'RESET':
      return initialState;
    case 'SET_USER':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const UserManagement: React.FC = () => {
  const { closeModal, openModal, showModal } = useShowModal();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get('id');
  const deletedId = searchParams.get('deleted_id');

  const [users, setUsers] = useState(usersData);
  useEffect(() => {
    if (deletedId) {
      const filteredUsers = users.filter(user => {
        const idToDelete = Number(deletedId); // Convert to number
        return isNaN(idToDelete) ? true : user.id !== idToDelete;
      });
      setUsers(filteredUsers);
    }
  }, [deletedId, users]);

  // const filteredData = usersData.filter(user =>
  //   Object.values(user).some(val =>
  //     String(val).toLowerCase().includes(searchTerm.toLowerCase())
  //   )
  // );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm<State>();

  // const handleExportCSV = useExportCSV(usersConfig, usersData);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;

    let payload: any;
    if (type === 'checkbox') {
      payload = (e.target as HTMLInputElement).checked;
    } else {
      payload = value;
    }

    dispatch({
      type: 'SET_FIELD',
      fieldName: name,
      payload,
    });

    clearErrors(name as keyof State);
  };

  const handleDeleteUser = (id: number) => {
    console.log('id: ', id);
  };

  const onSubmit: SubmitHandler<State> = data => {
    // if (state.password !== state.confirmPassword) {
    //   toast.error('Passwords do not match.');
    //   return;
    // }
    const currentDate = new Date().toISOString().split('T')[0];
    const newUser = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      phone: state.phone,
      // password: state.password,
      dateCreated: currentDate,
      role: state.isAdmin ? 'Admin' : 'Normal',
    };
    console.log(newUser);
    if (userId) {
      toast.success('User has been updated successfully.');
    } else {
      toast.success('User has been created successfully.');
    }
    dispatch({ type: 'RESET' });
    closeModal();
  };

  useEffect(() => {
    if (showModal) {
      reset();
      if (userId) {
        const user = usersData.find(user => user.id.toString() === userId);
        if (user) {
          dispatch({
            type: 'SET_USER',
            payload: {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              phone: user.phone,
              dateCreated: user.dateCreated,
              isAdmin: user.role === 'Admin',
            },
          });
        }
      }
    }
  }, [showModal, userId, reset]);

  useEffect(() => {
    if (userId && !showModal) {
      dispatch({ type: `RESET` });
    }
  }, [showModal, userId, setSearchParams]);

  return (
    <div className='p-4'>
      <div className='flex justify-end items-center mb-4 gap-2'>
        {/* <Button onClick={handleExportCSV} primary>
          <span>Export </span> <BsFiletypeCsv size={20} />
        </Button> */}
        <Button onClick={openModal} primary>
          Add new user
        </Button>
      </div>
      <div className=''>
        <Table
          data={users}
          config={usersConfig}
          showExportButton={false}
          onEdit={openModal}
          onDeleteUser={handleDeleteUser}
          showActions
          width='w-full'
        />
      </div>

      <Modal
        isOpen={showModal}
        onClose={() => {
          closeModal();
          setSearchParams('');
        }}
        title={userId ? 'Update user' : 'Create new user'}
      >
        <div className='p-4 max-w-xl mx-auto w-[20rem] md:w-[50rem]'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-3'>
              <div className='w-full'>
                <label
                  htmlFor='firstName'
                  className='block text-sm font-medium mb-1'
                >
                  First Name <RedStar />
                </label>
                <input
                  type='text'
                  id='firstName'
                  {...register('firstName', {
                    required: 'First Name is required',
                  })}
                  value={state.firstName}
                  onChange={handleInputChange}
                  className={`w-full px-3 py-2 border border-gray-300 rounded ${
                    errors.firstName && 'border-red-500'
                  }`}
                  placeholder='First Name'
                />
                {errors.firstName && (
                  <p className='text-red-400 text-sm'>
                    {errors.firstName.message}
                  </p>
                )}
              </div>
              <div className='w-full'>
                <label
                  htmlFor='lastName'
                  className='block text-sm font-medium mb-1'
                >
                  Last Name
                </label>
                <input
                  type='text'
                  value={state.lastName}
                  placeholder='Last Name'
                  id='lastName'
                  name='lastName'
                  onChange={handleInputChange}
                  className='w-full px-3 py-2 border border-gray-300 rounded'
                />
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-3'>
              <div className='w-full'>
                <label
                  htmlFor='email'
                  className='block text-sm font-medium mb-1'
                >
                  Email <RedStar />
                </label>
                <input
                  type='email'
                  id='email'
                  placeholder='E-Mail'
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Invalid email address',
                    },
                  })}
                  value={state.email}
                  onChange={handleInputChange}
                  className={`w-full px-3 py-2 border border-gray-300 rounded ${
                    errors.email && 'border-red-500'
                  }`}
                />
                {errors.email && (
                  <p className='text-red-400 text-sm'>
                    {errors.email?.message}
                  </p>
                )}
              </div>
              <div className='w-full'>
                <label
                  htmlFor='phone'
                  className='block text-sm font-medium mb-1'
                >
                  Phone
                </label>
                <input
                  type='tel'
                  id='phone'
                  className={`w-full px-3 py-2 border border-gray-300 rounded ${
                    errors.phone && 'border-red-500'
                  }`}
                  placeholder='Phone Number'
                  // {...register('phone', {
                  //   required: 'Phone Number is required',
                  // })}
                  value={state.phone}
                  onChange={handleInputChange}
                />
                {/* {errors.phone && (
                  <p className='text-red-400 text-sm'>{errors.phone.message}</p>
                )} */}
              </div>
            </div>
            {/* <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-3'>
              <div className='w-full'>
                <label
                  htmlFor='password'
                  className='block text-sm font-medium mb-1'
                >
                  Password <RedStar />
                </label>
                <input
                  type='password'
                  id='password'
                  value={state.password}
                  placeholder='Password'
                  {...register('password', {
                    required: 'Password Number is required',
                  })}
                  onChange={handleInputChange}
                  className={`w-full px-3 py-2 border border-gray-300 rounded ${
                    errors.password && 'border-red-500'
                  }`}
                />
                {errors.password && (
                  <p className='text-red-400 text-sm'>
                    {errors.password.message}
                  </p>
                )}
              </div>
              <div className='w-full'>
                <label
                  htmlFor='confirmPassword'
                  className='block text-sm font-medium mb-1'
                >
                  Confirm Password <RedStar />
                </label>
                <input
                  type='password'
                  id='confirmPassword'
                  value={state.confirmPassword}
                  placeholder='Confirm Password'
                  {...register('confirmPassword', {
                    required: 'Confirm Password Number is required',
                  })}
                  onChange={handleInputChange}
                  className={`w-full px-3 py-2 border border-gray-300 rounded ${
                    errors.confirmPassword && 'border-red-500'
                  }`}
                />
                {errors.confirmPassword && (
                  <p className='text-red-400 text-sm'>
                    {errors.confirmPassword.message}
                  </p>
                )}
              </div>
            </div> */}
            <div className='mb-4 w-full mt-2'>
              <label className='block mt-6 text-md font-normal'>
                <input
                  type='checkbox'
                  name='isAdmin'
                  id='isAdmin'
                  checked={state.isAdmin}
                  onChange={handleInputChange}
                  className='mr-2 accent-primary'
                />
                Admin
              </label>
            </div>
            <div className='flex justify-end'>
              <Button primary type='submit'>
                {userId ? 'Update' : 'Create user'}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <Toaster position='top-center' />
    </div>
  );
};

export default UserManagement;
