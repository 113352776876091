import React, { useState } from 'react';
import Button from '../../components/button/Button';
import toast, { Toaster } from 'react-hot-toast';
import { Icons } from '../../assets/SVG-Icons';

const firstContent = [
  {
    id: 1,
    title: 'Spend. Earn. Enjoy',
    content:
      '[ENTER YOUR PROGRAM NAME] Savers App offers you an opportunity to earn cashback at thousands of merchants when you shop, dine, or book hotels.  No sign-up costs, monthly fees, coupons or loyalty cards required – simply download our app, search participating merchants and pay with your enrolled cards. It´s uncapped, unlimited real money paid onto your preferred card every month!',
  },
  {
    id: 2,
    title: 'Cashback at Every Turn',
    content:
      'Whether you’re shopping close to home or planning a getaway, [Your Program Name] gives you more ways to earn. Enjoy cashback on dining, shopping, and travel essentials, with up to 50% cashback on 850,000+ hotels worldwide. With no monthly fees or loyalty cards required, it’s a straightforward way to save big on every purchase and earn automatic cashback every month. Enroll your card and get started on your journey to bigger savings today!',
  },
  {
    id: 3,
    title: 'Spend. Earn. Travel More',
    content:
      'With [Your Program Name] Savers App, you’re not just earning cashback on everyday purchases—you’re unlocking amazing savings on travel, too. Enjoy cashback rewards at thousands of restaurants, retail stores, and up to 50% cashback on more than 850,000 hotels worldwide! Whether you’re booking a weekend getaway or a dream vacation, our app helps you save big on every adventure. It’s easy: download the app, use your enrolled card, and earn real, uncapped cashback automatically deposited to your preferred card each month. Start turning your daily spending into exciting rewards and unforgettable travel experiences!',
  },
  {
    id: 4,
    title: 'Cashback at Every Step',
    content:
      'Whether you’re dining out, shopping, or booking a trip, [Your Program Name] has you covered. Earn unlimited cashback on purchases and save up to 50% on hotels around the world. Enjoy big savings without the hassle!',
  },
  {
    id: 5,
    title: 'Shop Smart, Save Big',
    content:
      'Unlock cashback with [Your Program Name] Rewards. From dining to shopping to hotels, simply pay with your enrolled card and start saving. Real cash, no limits, directly to your card each month!',
  },
  {
    id: 6,
    title: 'Cashback Made Easy',
    content:
      'With [Your Program Name] Rewards, you can earn real cashback at thousands of locations when you shop, dine out, or book travel. No extra steps or hidden fees—just use your enrolled card and watch the savings add up!',
  },
];

const secondContent = [
  { id: 1, text: 'Get up to 50% cashback on hotels' },
  { id: 2, text: 'Get 4-star hotels for 2-star pricing' },
  { id: 3, text: 'Earn cashback and start saving today' },
  { id: 4, text: 'Shop from brand name stores and earn cashback' },
  { id: 5, text: 'Get our rewards app today' },
  { id: 6, text: 'Dine & earn cashback at over 20,000 restaurants' },
  { id: 7, text: 'Unlock exclusive cashback today!' },
  { id: 8, text: 'Cashback on every purchase' },
  { id: 9, text: 'Instant savings, just for you' },
  { id: 10, text: 'Why just shop when you can earn?' },
];

const thirdListContent = [
  { id: 1, text: 'Give Thanks, Save Big!' },
  { id: 2, text: 'Thanksgiving Savings Await' },
  { id: 4, text: 'Give Thanks and Give Back with Savings!' },
  { id: 5, text: 'Savor the Season, Save on Every Bite' },
  { id: 6, text: 'Thankful for Big Savings' },
  { id: 7, text: 'Save on Gifts for Your Loved Ones This Thanksgiving' },
];

const fourthListContent = [
  {
    id: 1,
    text: 'How do we get such great deals? Because of our large private membership network, we are able to tap into a deeply discounted pool of deals from popular hotel brands all over the world.',
  },
  {
    id: 2,
    text: 'Travel has never been more affordable. Sign up for [Your Program Name] today. It’s free for members!',
  },
  {
    id: 4,
    text: "[Your Program Name] offers up to 50% cashback on hotels all over the world. These deals are only offered to our members and aren't available to the public.",
  },
  {
    id: 5,
    text: 'Why settle for less when you can easily get more? With [Your Program Name], our cashback on hotel bookings gives you more for your money —a 4-star hotel for 2-star pricing.',
  },
  {
    id: 6,
    text: 'Get rewarded for everyday purchases! Download our cashback app and earn money back at thousands of restaurants, hotels, and stores. Start saving today!',
  },
  {
    id: 7,
    text: 'Shopping just got better. Use our cashback app to earn rewards every time you dine, travel, or shop with top brands. It’s free, easy, and pays you back!',
  },
  {
    id: 8,
    text: 'With our cashback app, you can earn up to 50% back on hotel stays, restaurant visits, and more! Enroll your card to start enjoying rewards instantly.',
  },
  {
    id: 9,
    text: 'Download our cashback app and turn everyday purchases into savings. Earn cashback at popular spots, from restaurants to top retail stores. Save more with every swipe!',
  },
  {
    id: 10,
    text: 'Use our cashback app to get rewarded on purchases at thousands of locations. The more you shop, the more you save!',
  },
  {
    id: 11,
    text: 'Love shopping at top brands? With our cashback app, you can earn money back every time you buy from popular stores online. It’s easy to save while you shop!',
  },
  {
    id: 12,
    text: 'Shop at your favorite brand-name stores through our cashback app and enjoy automatic cashback on every purchase. Start earning on brands you love!',
  },
  {
    id: 13,
    text: 'From fashion to electronics, earn cashback on top brands every time you shop online. Download our app and start turning your purchases into rewards!',
  },
  {
    id: 14,
    text: 'Shopping online has never been this rewarding! Get cashback on top brands with every purchase —just by shopping through our app.',
  },
];

const thirdList2Content = [
  {
    id: 1,
    text: 'Get ready to enjoy a Thanksgiving full of savings! From your holiday meal essentials to last-minute décor, our cashback offers make it easy to save big while celebrating with loved ones.',
  },
  {
    id: 2,
    text: 'Gather around the table without breaking the bank. Our app helps you save on Thanksgiving groceries, dining out, and even travel for those special holiday visits. Start earning cashback today and make this Thanksgiving one to remember!',
  },
  {
    id: 4,
    text: "This Thanksgiving, focus on the feast and leave the savings to us! With cashback on groceries, restaurants, and holiday shopping, you'll keep more cash in your pocket to make the season even more memorable.",
  },
  {
    id: 5,
    text: 'Celebrate Thanksgiving with unbeatable savings! Enjoy cashback on everything from holiday groceries to dining out and make your Thanksgiving prep easier—and more affordable.',
  },
  {
    id: 6,
    text: 'Make this Thanksgiving truly special with gifts and treats for your loved ones. From holiday meals to thoughtful gifts, earn cashback on every purchase, so you can give more without spending more.',
  },
  {
    id: 7,
    text: 'Give thanks and save big on the things that matter most. From family gatherings to special gifts, our cashback rewards help you celebrate with those you love without breaking the bank.',
  },
];

function Messaging() {
  const [activeTab, setActiveTab] = useState<
    'first' | 'second' | 'third' | 'fourth'
  >('first');

  const handleCopy = (url: string) => {
    navigator.clipboard.writeText(url);
    toast.success('Link copied!');
  };

  return (
    <section className='px-6 mb-8'>
      <div className='mt-5'>
        <h3 className='text-2xl font-bold mb-[1.15rem]'>Messaging</h3>
        <p className='text-[15px]'>
          To support the launch and promotion of your Savers App loyalty app,
          we’ve provided a variety of assets, including suggested messaging,
          social media content, web/email banners, product images, and a welcome
          email campaign. Each piece is designed to help your customers,
          employees, or members sign up quickly and start enjoying savings and
          cashback rewards. Please refer to the marketing section guidelines to
          help you get started and maintain ongoing promotion of your app.
        </p>
      </div>

      <div className='flex flex-wrap gap-5 w-full mt-8 border-b pb-8 mb-8'>
        <Button
          className={` ${
            activeTab === 'first'
              ? 'bg-[#00C389] text-white'
              : 'text-black bg-white'
          } font-semibold text-lg px-8 text-wrap`}
          onClick={() => setActiveTab('first')}
        >
          Suggested Messaging
        </Button>
        <Button
          className={` ${
            activeTab === 'second'
              ? 'bg-[#00C389] text-white'
              : 'text-black bg-white'
          } font-semibold text-lg px-8 text-wrap`}
          onClick={() => setActiveTab('second')}
        >
          Suggested Headline and Tagline Messaging
        </Button>
        <Button
          className={` ${
            activeTab === 'third'
              ? 'bg-[#00C389] text-white'
              : 'text-black bg-white'
          } font-semibold text-lg px-8 text-wrap`}
          onClick={() => setActiveTab('third')}
        >
          Thanksgiving - Special
        </Button>
        <Button
          className={` ${
            activeTab === 'fourth'
              ? 'bg-[#00C389] text-white'
              : 'text-black bg-white'
          } font-semibold text-lg px-8 text-wrap`}
          onClick={() => setActiveTab('fourth')}
        >
          Blurbs for Email, Social Media, Website and Landing Pages
        </Button>
      </div>

      {activeTab === 'first' &&
        firstContent.map(item => (
          <div key={item.id} className='mb-6'>
            <div className='flex gap-3 items-center mb-3'>
              <h4 className='font-bold text-xl'>{item.title}</h4>
              <button onClick={() => handleCopy(item.title)}>
                {Icons.copy()}
              </button>
            </div>
            <p className='inline mr-2'>{item.content}</p>
            <button onClick={() => handleCopy(item.content)}>
              {Icons.copy()}
            </button>
          </div>
        ))}

      {activeTab === 'second' && (
        <ol className='list-decimal space-y-4 ml-5'>
          {secondContent.map(item => (
            <li key={item.id}>
              <div className='flex gap-2 items-center'>
                <p>{item.text}</p>
                <button onClick={() => handleCopy(item.text)}>
                  {Icons.copy()}
                </button>
              </div>
            </li>
          ))}
        </ol>
      )}

      {/* Thanksgiving - Special content */}
      {activeTab === 'third' && (
        <>
          <div className='flex gap-2 mb-4'>
            <h4 className='font-bold text-xl'>Taglines - Headlines</h4>
            {/* <button onClick={() => handleCopy('Taglines - Headlines')}>
              {Icons.copy()}
            </button> */}
          </div>
          <ol className='list-decimal space-y-4 ml-5 mb-8'>
            {thirdListContent.map(item => (
              <li key={item.id}>
                <div className='flex gap-2 items-center'>
                  <p>{item.text}</p>
                  <button onClick={() => handleCopy(item.text)}>
                    {Icons.copy()}
                  </button>
                </div>
              </li>
            ))}
          </ol>
          <div className='flex gap-2 mb-4'>
            <h4 className='font-bold text-xl'>
              Blurbs for Email, Social Mdeia, Website and Landing Pages
            </h4>
            {/* <button
              onClick={() =>
                handleCopy(
                  'Blurbs for Email, Social Mdeia, Website and Landing Pages'
                )
              }
            >
              {Icons.copy()}
            </button> */}
          </div>
          {thirdList2Content.map(item => (
            <div key={item.id} className='gap-2 mb-6'>
              <span className='mt-4 mr-2'>{item.text}</span>
              <button onClick={() => handleCopy(item.text)}>
                {Icons.copy()}
                {/* <svg
                  width='15'
                  height='20'
                  viewBox='0 0 15 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M10.6281 1H3.16667V3.25H1V19H11.8333V16.75H14V4.51562L10.6281 1ZM11.1111 18.25H1.72222V4H3.16667V16.75H11.1111V18.25ZM13.2778 16H3.88889V1.75H10.3302L13.2778 4.825V16Z'
                    fill='black'
                    stroke='black'
                    strokeWidth='0.3'
                  />
                </svg> */}
              </button>
            </div>
          ))}
        </>
      )}

      <div className='w-[45%]'>
        {activeTab === 'fourth' &&
          fourthListContent.map(item => (
            <div key={item.id} className='gap-2 mb-6'>
              <span className='mt-4 mr-2 leading-7'>{item.text}</span>
              <button onClick={() => handleCopy(item.text)}>
                {Icons.copy()}
              </button>
            </div>
          ))}
      </div>
      <Toaster />
    </section>
  );
}

export default Messaging;
