//<Bar dataKey='activeUsers' fill='#1133BB' name='Active Users' />
// {!isOneColumn && (
//   <Bar dataKey='inactiveUsers' fill='#66CC99' name='Inactive Users' />
// )}
import React, { useCallback, useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { FilterByDateWithFlag } from '../lineChart/LineChart';
import Spinner from '../spinner/Spinner';

interface DataItem {
  name: string;
  [key: string]: string | number;
}

interface Props {
  title?: string;
  data?: DataItem[];
  dataKey1?: string;
  dataKey2?: string;
  name1?: string;
  name2?: string;
  description?: string;
  isFiltering?: boolean;
  isOneColumn?: boolean;
  defaualtFiltering?: string;
  chartName?: any;
  dollar?: boolean;
  setCustomDate?: React.Dispatch<React.SetStateAction<FilterByDateWithFlag>>;
  isLoading?: boolean;
}

const BarchartComp = ({
  title,
  data = [],
  description,
  isFiltering,
  isOneColumn,
  dataKey1 = 'activeUsers',
  dataKey2 = 'inactiveUsers',
  name1 = 'Active Members',
  name2 = 'Inactive Members',
  dollar = false,
  defaualtFiltering = '9',
  setCustomDate,
  chartName,
  isLoading,
}: Props) => {
  const [filter, setFilter] = useState(defaualtFiltering); // Default filter: 3 months
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const [dateError, setDateError] = useState<string | null>(null);

  const validateDates = () => {
    if (fromDate && toDate) {
      const from = new Date(fromDate);
      const to = new Date(toDate);

      if (to <= from) {
        setDateError('The "To" date must be later than the "From" date.');
        return false;
      }
    }
    setDateError(null);
    return true;
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter(e.target.value);
  };

  const handleDateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: 'from' | 'to'
  ) => {
    const value = e.target.value;
    if (type === 'from') {
      setFromDate(value);
    } else {
      setToDate(value);
    }
    validateDates();
  };

  // const filteredData = () => {
  //   const today = new Date();
  //   let startDate, endDate;

  //   if (filter === '3') {
  //     endDate = new Date(today.getFullYear(), today.getMonth(), 0);
  //     startDate = new Date(today.getFullYear(), today.getMonth() - 3, 1);
  //     startDate.setHours(12, 0, 0, 0);
  //   } else if (filter === '6') {
  //     endDate = new Date(today.getFullYear(), today.getMonth(), 0);
  //     startDate = new Date(today.getFullYear(), today.getMonth() - 6, 1);
  //     startDate.setHours(12, 0, 0, 0);
  //   } else if (filter === '9') {
  //     endDate = new Date(today.getFullYear(), today.getMonth(), 0);
  //     startDate = new Date(today.getFullYear(), today.getMonth() - 9, 1);
  //     startDate.setHours(12, 0, 0, 0);
  //   } else if (filter === '12') {
  //     endDate = new Date(today.getFullYear(), today.getMonth(), 0);
  //     startDate = new Date(today);
  //     startDate.setFullYear(today.getFullYear(), today.getMonth() - 12, 1);
  //     startDate.setHours(12, 0, 0, 0);
  //   }
  //   if (filter !== 'custom') {
  //     setCustomDate?.({
  //       endDate: endDate?.toISOString() || '',
  //       startDate: startDate?.toISOString() || '',
  //       typeName: chartName || '',
  //     });
  //   }
  // };
  const filteredData = useCallback(() => {
    const today = new Date();
    let startDate, endDate;

    if (filter === '3') {
      endDate = new Date(today.getFullYear(), today.getMonth(), 0);
      startDate = new Date(today.getFullYear(), today.getMonth() - 3, 1);
      startDate.setHours(12, 0, 0, 0);
    } else if (filter === '6') {
      endDate = new Date(today.getFullYear(), today.getMonth(), 0);
      startDate = new Date(today.getFullYear(), today.getMonth() - 6, 1);
      startDate.setHours(12, 0, 0, 0);
    } else if (filter === '9') {
      endDate = new Date(today.getFullYear(), today.getMonth(), 0);
      startDate = new Date(today.getFullYear(), today.getMonth() - 9, 1);
      startDate.setHours(12, 0, 0, 0);
    } else if (filter === '12') {
      endDate = new Date(today.getFullYear(), today.getMonth(), 0);
      startDate = new Date(today);
      startDate.setFullYear(today.getFullYear(), today.getMonth() - 12, 1);
      startDate.setHours(12, 0, 0, 0);
    }
    if (filter !== 'custom') {
      setCustomDate?.({
        endDate: endDate?.toISOString() || '',
        startDate: startDate?.toISOString() || '',
        typeName: chartName || '',
      });
    }
  }, [filter, chartName, setCustomDate]);

  const handleSubmitDate = () => {
    if (!toDate || !fromDate) return;
    if (validateDates() && setCustomDate) {
      setCustomDate({
        endDate: toDate,
        startDate: fromDate,
        typeName: chartName,
      });
    }
  };

  useEffect(() => {
    filteredData();
  }, [filter, filteredData]);

  return (
    <div className='flex flex-col'>
      {title && (
        <h2 className='mt-4 font-[600] text-xl text-center'>{title}</h2>
      )}
      <div className='flex gap-8 mb-8 mt-4 max-h-10'>
        {isFiltering && (
          <div className='select-wrapper'>
            <select
              value={filter}
              onChange={handleFilterChange}
              className='p-2 border border-gray-300 rounded cursor-pointer pr-6'
            >
              <option value='3'>Last 3 Months</option>
              <option value='6'>Last 6 Months</option>
              <option value='9'>Last 9 Months</option>
              <option value='12'>Last 12 Months</option>
              <option value='custom'>Custom</option>
            </select>
          </div>
        )}

        {filter === 'custom' && (
          <div className='flex flex-col gap-2'>
            <div className='flex items-center gap-2'>
              <label htmlFor='fromDate'>From</label>
              <input
                id='fromDate'
                type='date'
                value={fromDate}
                onChange={e => handleDateChange(e, 'from')}
                className='p-2 border border-gray-300 rounded'
              />
              <label htmlFor='toDate' className='ml-4'>
                To
              </label>

              <input
                type='date'
                id='toDate'
                value={toDate}
                onChange={e => handleDateChange(e, 'to')}
                className='p-2 border border-gray-300 rounded'
              />
              <button
                className={`border border-gray-300 px-4 py-1 rounded ml-2 h-full  whitespace-nowrap ${
                  !toDate || !fromDate
                    ? 'bg-[#ccc] text-black'
                    : 'bg-[#00C389] text-white'
                }`}
                type='button'
                onClick={handleSubmitDate}
                disabled={!toDate || !fromDate}
              >
                Submit Date
              </button>
            </div>
            {dateError && (
              <p className='text-red-500 text-sm text-center'>{dateError}</p>
            )}
          </div>
        )}
      </div>
      {isLoading ? (
        <div className='mx-auto my-5'>
          <Spinner />
        </div>
      ) : data ? (
        <>
          <ResponsiveContainer width='100%' height={300}>
            <BarChart
              data={[...data].reverse()}
              margin={{
                top: 5,
                right: 30,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' />
              <YAxis
                type='number'
                // tickFormatter={value => (dollar ? `${value}$` : value)}
                tickFormatter={value =>
                  dollar
                    ? `$${new Intl.NumberFormat().format(value)}`
                    : new Intl.NumberFormat().format(value)
                }
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: '#fff',
                  border: '1px solid #ddd',
                }}
                itemStyle={{ background: 'white' }}
                formatter={value => (dollar ? `$${value}` : value)}
              />
              <Legend />
              <Bar dataKey={dataKey1} fill='#1133BB' name={name1} />
              {!isOneColumn && (
                <Bar dataKey={dataKey2} fill='#66CC99' name={name2} />
              )}
            </BarChart>
          </ResponsiveContainer>
          <p className='my-4 text-sm text-center mt-6'>{description}</p>
        </>
      ) : (
        <p className='italic text-gray-600 text-center'>No data available</p>
      )}
    </div>
  );
};

export default BarchartComp;
