import SC_Banner_1_120x600 from '../webAdImages/120x600/SC_Banner_1_120x600 px.png';
import SC_Banner_2_120x600 from '../webAdImages/120x600/SC_Banner_2_120x600 px.png';
import SC_Banner_3_120x600 from '../webAdImages/120x600/SC_Banner_3_120x600 px.png';
import SC_Banner_4_120x600 from '../webAdImages/120x600/SC_Banner_4_120x600 px.png';
import SC_Banner_5_120x600 from '../webAdImages/120x600/SC_Banner_5_120x600 px.png';
import SC_Banner_7_120x600 from '../webAdImages/120x600/SC_Banner_7_120x600 px.png';
import SC_Banner_8_120x600 from '../webAdImages/120x600/SC_Banner_8_120x600 px.png';
import SC_Banner_9_120x600 from '../webAdImages/120x600/SC_Banner_9_120x600 px.png';
import SC_Banner_10_120x600 from '../webAdImages/120x600/SC_Banner_10_120x600 px.png';
import SC_Banner_11_120x600 from '../webAdImages/120x600/SC_Banner_11_120x600 px.png';
import SC_Banner_12_120x600 from '../webAdImages/120x600/SC_Banner_12_120x600 px.png';
import SC_Banner_16_120x600 from '../webAdImages/120x600/SC_Banner_16_120x600 px.png';
import SC_Banner_17_120x600 from '../webAdImages/120x600/SC_Banner_17_120x600 px.png';
import SC_Banner_18_120x600 from '../webAdImages/120x600/SC_Banner_18_120x600 px.png';
import SC_Banner_20_120x600 from '../webAdImages/120x600/SC_Banner_20_120x600 px.png';
import SC_Banner_21_120x600 from '../webAdImages/120x600/SC_Banner_21_120x600 px.png';
import SC_Banner_22_120x600 from '../webAdImages/120x600/SC_Banner_22_120x600 px.png';
import SC_Banner_23_120x600 from '../webAdImages/120x600/SC_Banner_23_120x600 px.png';
import SC_Banner_24_120x600 from '../webAdImages/120x600/SC_Banner_24_120x600 px.png';
import SC_Banner_25_120x600 from '../webAdImages/120x600/SC_Banner_25_120x600 px.png';

import SC_Banner_1_160x600 from '../webAdImages/160x600/SC_Banner_1_160x600 px.png';
import SC_Banner_2_160x600 from '../webAdImages/160x600/SC_Banner_2_160x600 px.png';
import SC_Banner_3_160x600 from '../webAdImages/160x600/SC_Banner_3_160x600 px.png';
import SC_Banner_4_160x600 from '../webAdImages/160x600/SC_Banner_4_160x600 px.png';
import SC_Banner_5_160x600 from '../webAdImages/160x600/SC_Banner_5_160x600 px.png';
import SC_Banner_7_160x600 from '../webAdImages/160x600/SC_Banner_7_160x600 px.png';
import SC_Banner_8_160x600 from '../webAdImages/160x600/SC_Banner_8_160x600 px.png';
import SC_Banner_9_160x600 from '../webAdImages/160x600/SC_Banner_9_160x600 px.png';
import SC_Banner_10_160x600 from '../webAdImages/160x600/SC_Banner_10_160x600 px.png';
import SC_Banner_11_160x600 from '../webAdImages/160x600/SC_Banner_11_160x600 px.png';
import SC_Banner_12_160x600 from '../webAdImages/160x600/SC_Banner_12_160x600 px.png';
import SC_Banner_16_160x600 from '../webAdImages/160x600/SC_Banner_16_160x600 px.png';
import SC_Banner_17_160x600 from '../webAdImages/160x600/SC_Banner_17_160x600 px.png';
import SC_Banner_18_160x600 from '../webAdImages/160x600/SC_Banner_18_160x600 px.png';
import SC_Banner_19_160x600 from '../webAdImages/160x600/SC_Banner_19_160x600 px.png';
import SC_Banner_20_160x600 from '../webAdImages/160x600/SC_Banner_20_160x600 px.png';
import SC_Banner_21_160x600 from '../webAdImages/160x600/SC_Banner_21_160x600 px.png';
import SC_Banner_22_160x600 from '../webAdImages/160x600/SC_Banner_22_160x600 px.png';
import SC_Banner_23_160x600 from '../webAdImages/160x600/SC_Banner_23_160x600 px.png';
import SC_Banner_24_160x600 from '../webAdImages/160x600/SC_Banner_24_160x600 px.png';
import SC_Banner_25_160x600 from '../webAdImages/160x600/SC_Banner_25_160x600 px.png';
import SC_Banner_26_160x600 from '../webAdImages/160x600/SC_Banner_26_160x600 px.png';
import SC_Banner_27_160x600 from '../webAdImages/160x600/SC_Banner_27_160x600 px.png';

import SC_Banner_1_250x250 from '../webAdImages/250x250/SC_Banner_1_250x250 px.png';
import SC_Banner_2_250x250 from '../webAdImages/250x250/SC_Banner_2_250x250 px.png';
import SC_Banner_3_250x250 from '../webAdImages/250x250/SC_Banner_3_250x250 px.png';
import SC_Banner_4_250x250 from '../webAdImages/250x250/SC_Banner_4_250x250 px.png';
import SC_Banner_5_250x250 from '../webAdImages/250x250/SC_Banner_5_250x250 px.png';
import SC_Banner_7_250x250 from '../webAdImages/250x250/SC_Banner_7_250x250 px.png';
import SC_Banner_8_250x250 from '../webAdImages/250x250/SC_Banner_8_250x250 px.png';
import SC_Banner_9_250x250 from '../webAdImages/250x250/SC_Banner_9_250x250 px.png';
import SC_Banner_10_250x250 from '../webAdImages/250x250/SC_Banner_10_250x250 px.png';
import SC_Banner_11_250x250 from '../webAdImages/250x250/SC_Banner_11_250x250 px.png';
import SC_Banner_12_250x250 from '../webAdImages/250x250/SC_Banner_12_250x250 px.png';
import SC_Banner_16_250x250 from '../webAdImages/250x250/SC_Banner_16_250x250 px.png';
import SC_Banner_17_250x250 from '../webAdImages/250x250/SC_Banner_17_250x250 px.png';
import SC_Banner_18_250x250 from '../webAdImages/250x250/SC_Banner_18_250x250 px.png';
import SC_Banner_19_250x250 from '../webAdImages/250x250/SC_Banner_19_250x250 px.png';
import SC_Banner_20_250x250 from '../webAdImages/250x250/SC_Banner_20_250x250 px.png';
import SC_Banner_21_250x250 from '../webAdImages/250x250/SC_Banner_21_250x250 px.png';
import SC_Banner_22_250x250 from '../webAdImages/250x250/SC_Banner_22_250x250 px.png';
import SC_Banner_23_250x250 from '../webAdImages/250x250/SC_Banner_23_250x250 px.png';
import SC_Banner_24_250x250 from '../webAdImages/250x250/SC_Banner_24_250x250 px.png';
import SC_Banner_25_250x250 from '../webAdImages/250x250/SC_Banner_25_250x250 px.png';
import SC_Banner_26_250x250 from '../webAdImages/250x250/SC_Banner_26_250x250 px.png';
import SC_Banner_27_250x250 from '../webAdImages/250x250/SC_Banner_27_250x250 px.png';

import SC_Banner_1_300x250 from '../webAdImages/300x250/SC_Banner_1_300x250 px.png';
import SC_Banner_2_300x250 from '../webAdImages/300x250/SC_Banner_2_300x250 px.png';
import SC_Banner_3_300x250 from '../webAdImages/300x250/SC_Banner_3_300x250 px.png';
import SC_Banner_4_300x250 from '../webAdImages/300x250/SC_Banner_4_300x250 px.png';
import SC_Banner_5_300x250 from '../webAdImages/300x250/SC_Banner_5_300x250 px.png';
import SC_Banner_7_300x250 from '../webAdImages/300x250/SC_Banner_7_300x250 px.png';
import SC_Banner_8_300x250 from '../webAdImages/300x250/SC_Banner_8_300x250 px.png';
import SC_Banner_9_300x250 from '../webAdImages/300x250/SC_Banner_9_300x250 px.png';
import SC_Banner_10_300x250 from '../webAdImages/300x250/SC_Banner_10_300x250 px.png';
import SC_Banner_11_300x250 from '../webAdImages/300x250/SC_Banner_11_300x250 px.png';
import SC_Banner_12_300x250 from '../webAdImages/300x250/SC_Banner_12_300x250 px.png';
import SC_Banner_16_300x250 from '../webAdImages/300x250/SC_Banner_16_300x250 px.png';
import SC_Banner_17_300x250 from '../webAdImages/300x250/SC_Banner_17_300x250 px.png';
import SC_Banner_18_300x250 from '../webAdImages/300x250/SC_Banner_18_300x250 px.png';
import SC_Banner_19_300x250 from '../webAdImages/300x250/SC_Banner_19_300x250 px.png';
import SC_Banner_20_300x250 from '../webAdImages/300x250/SC_Banner_20_300x250 px.png';
import SC_Banner_21_300x250 from '../webAdImages/300x250/SC_Banner_21_300x250 px.png';
import SC_Banner_22_300x250 from '../webAdImages/300x250/SC_Banner_22_300x250 px.png';
import SC_Banner_23_300x250 from '../webAdImages/300x250/SC_Banner_23_300x250 px.png';
import SC_Banner_24_300x250 from '../webAdImages/300x250/SC_Banner_24_300x250 px.png';
import SC_Banner_25_300x250 from '../webAdImages/300x250/SC_Banner_25_300x250 px.png';
import SC_Banner_26_300x250 from '../webAdImages/300x250/SC_Banner_26_300x250 px.png';
import SC_Banner_27_300x250 from '../webAdImages/300x250/SC_Banner_27_300x250 px.png';

import SC_Banner_1_300x600 from '../webAdImages/300x600/SC_Banner_1_300x600 px.png';
import SC_Banner_2_300x600 from '../webAdImages/300x600/SC_Banner_2_300x600 px.png';
import SC_Banner_3_300x600 from '../webAdImages/300x600/SC_Banner_3_300x600 px.png';
import SC_Banner_4_300x600 from '../webAdImages/300x600/SC_Banner_4_300x600 px.png';
import SC_Banner_5_300x600 from '../webAdImages/300x600/SC_Banner_5_300x600 px.png';
import SC_Banner_7_300x600 from '../webAdImages/300x600/SC_Banner_7_300x600 px.png';
import SC_Banner_8_300x600 from '../webAdImages/300x600/SC_Banner_8_300x600 px.png';
import SC_Banner_9_300x600 from '../webAdImages/300x600/SC_Banner_9_300x600 px.png';
import SC_Banner_10_300x600 from '../webAdImages/300x600/SC_Banner_10_300x600 px.png';
import SC_Banner_11_300x600 from '../webAdImages/300x600/SC_Banner_11_300x600 px.png';
import SC_Banner_12_300x600 from '../webAdImages/300x600/SC_Banner_12_300x600 px.png';
import SC_Banner_16_300x600 from '../webAdImages/300x600/SC_Banner_16_300x600 px.png';
import SC_Banner_17_300x600 from '../webAdImages/300x600/SC_Banner_17_300x600 px.png';
import SC_Banner_18_300x600 from '../webAdImages/300x600/SC_Banner_18_300x600 px.png';
import SC_Banner_19_300x600 from '../webAdImages/300x600/SC_Banner_19_300x600 px.png';
import SC_Banner_20_300x600 from '../webAdImages/300x600/SC_Banner_20_300x600 px.png';
import SC_Banner_21_300x600 from '../webAdImages/300x600/SC_Banner_21_300x600 px.png';
import SC_Banner_22_300x600 from '../webAdImages/300x600/SC_Banner_22_300x600 px.png';
import SC_Banner_23_300x600 from '../webAdImages/300x600/SC_Banner_23_300x600 px.png';
import SC_Banner_24_300x600 from '../webAdImages/300x600/SC_Banner_24_300x600 px.png';
import SC_Banner_25_300x600 from '../webAdImages/300x600/SC_Banner_25_300x600 px.png';
import SC_Banner_26_300x600 from '../webAdImages/300x600/SC_Banner_26_300x600 px.png';
import SC_Banner_27_300x600 from '../webAdImages/300x600/SC_Banner_27_300x600 px.png';

import SC_Banner_1_336x280 from '../webAdImages/336x280/SC_Banner_1_336x280 px.png';
import SC_Banner_2_336x280 from '../webAdImages/336x280/SC_Banner_2_336x280 px.png';
import SC_Banner_3_336x280 from '../webAdImages/336x280/SC_Banner_3_336x280 px.png';
import SC_Banner_4_336x280 from '../webAdImages/336x280/SC_Banner_4_336x280 px.png';
import SC_Banner_5_336x280 from '../webAdImages/336x280/SC_Banner_5_336x280 px.png';
import SC_Banner_7_336x280 from '../webAdImages/336x280/SC_Banner_7_336x280 px.png';
import SC_Banner_8_336x280 from '../webAdImages/336x280/SC_Banner_8_336x280 px.png';
import SC_Banner_9_336x280 from '../webAdImages/336x280/SC_Banner_9_336x280 px.png';
import SC_Banner_10_336x280 from '../webAdImages/336x280/SC_Banner_10_336x280 px.png';
import SC_Banner_11_336x280 from '../webAdImages/336x280/SC_Banner_11_336x280 px.png';
import SC_Banner_12_336x280 from '../webAdImages/336x280/SC_Banner_12_336x280 px.png';
import SC_Banner_16_336x280 from '../webAdImages/336x280/SC_Banner_16_336x280 px.png';
import SC_Banner_17_336x280 from '../webAdImages/336x280/SC_Banner_17_336x280 px.png';
import SC_Banner_18_336x280 from '../webAdImages/336x280/SC_Banner_18_336x280 px.png';
import SC_Banner_19_336x280 from '../webAdImages/336x280/SC_Banner_19_336x280 px.png';
import SC_Banner_20_336x280 from '../webAdImages/336x280/SC_Banner_20_336x280 px.png';
import SC_Banner_21_336x280 from '../webAdImages/336x280/SC_Banner_21_336x280 px.png';
import SC_Banner_22_336x280 from '../webAdImages/336x280/SC_Banner_22_336x280 px.png';
import SC_Banner_23_336x280 from '../webAdImages/336x280/SC_Banner_23_336x280 px.png';
import SC_Banner_24_336x280 from '../webAdImages/336x280/SC_Banner_24_336x280 px.png';
import SC_Banner_25_336x280 from '../webAdImages/336x280/SC_Banner_25_336x280 px.png';
import SC_Banner_26_336x280 from '../webAdImages/336x280/SC_Banner_26_336x280 px.png';
import SC_Banner_27_336x280 from '../webAdImages/336x280/SC_Banner_27_336x280 px.png';

import SC_Banner_1_420x250 from '../webAdImages/420x250/SC_Banner_1_420x250 px.png';
import SC_Banner_2_420x250 from '../webAdImages/420x250/SC_Banner_2_420x250 px.png';
import SC_Banner_3_420x250 from '../webAdImages/420x250/SC_Banner_3_420x250 px.png';
import SC_Banner_4_420x250 from '../webAdImages/420x250/SC_Banner_4_420x250 px.png';
import SC_Banner_5_420x250 from '../webAdImages/420x250/SC_Banner_5_420x250 px.png';
import SC_Banner_7_420x250 from '../webAdImages/420x250/SC_Banner_7_420x250 px.png';
import SC_Banner_8_420x250 from '../webAdImages/420x250/SC_Banner_8_420x250 px.png';
import SC_Banner_9_420x250 from '../webAdImages/420x250/SC_Banner_9_420x250 px.png';
import SC_Banner_10_420x250 from '../webAdImages/420x250/SC_Banner_10_420x250 px.png';
import SC_Banner_11_420x250 from '../webAdImages/420x250/SC_Banner_11_420x250 px.png';
import SC_Banner_12_420x250 from '../webAdImages/420x250/SC_Banner_12_420x250 px.png';
import SC_Banner_16_420x250 from '../webAdImages/420x250/SC_Banner_16_420x250 px.png';
import SC_Banner_17_420x250 from '../webAdImages/420x250/SC_Banner_17_420x250 px.png';
import SC_Banner_18_420x250 from '../webAdImages/420x250/SC_Banner_18_420x250 px.png';
import SC_Banner_19_420x250 from '../webAdImages/420x250/SC_Banner_19_420x250 px.png';
import SC_Banner_20_420x250 from '../webAdImages/420x250/SC_Banner_20_420x250 px.png';
import SC_Banner_21_420x250 from '../webAdImages/420x250/SC_Banner_21_420x250 px.png';
import SC_Banner_22_420x250 from '../webAdImages/420x250/SC_Banner_22_420x250 px.png';
import SC_Banner_23_420x250 from '../webAdImages/420x250/SC_Banner_23_420x250 px.png';
import SC_Banner_24_420x250 from '../webAdImages/420x250/SC_Banner_24_420x250 px.png';
import SC_Banner_25_420x250 from '../webAdImages/420x250/SC_Banner_25_420x250 px.png';
import SC_Banner_26_420x250 from '../webAdImages/420x250/SC_Banner_26_420x250 px.png';
import SC_Banner_27_420x250 from '../webAdImages/420x250/SC_Banner_27_420x250 px.png';

// Imports for 468x60 images
import SC_Banner_1_468x60 from '../webAdImages/468x60/SC_Banner_1_468x60 px.png';
import SC_Banner_2_468x60 from '../webAdImages/468x60/SC_Banner_2_468x60 px.png';
import SC_Banner_3_468x60 from '../webAdImages/468x60/SC_Banner_3_468x60 px.png';
import SC_Banner_4_468x60 from '../webAdImages/468x60/SC_Banner_4_468x60 px.png';
import SC_Banner_5_468x60 from '../webAdImages/468x60/SC_Banner_5_468x60 px.png';
import SC_Banner_7_468x60 from '../webAdImages/468x60/SC_Banner_7_468x60 px.png';
import SC_Banner_8_468x60 from '../webAdImages/468x60/SC_Banner_8_468x60 px.png';
import SC_Banner_9_468x60 from '../webAdImages/468x60/SC_Banner_9_468x60 px.png';
import SC_Banner_10_468x60 from '../webAdImages/468x60/SC_Banner_10_468x60 px.png';
import SC_Banner_11_468x60 from '../webAdImages/468x60/SC_Banner_11_468x60 px.png';
import SC_Banner_12_468x60 from '../webAdImages/468x60/SC_Banner_12_468x60 px.png';
import SC_Banner_16_468x60 from '../webAdImages/468x60/SC_Banner_16_468x60 px.png';
import SC_Banner_17_468x60 from '../webAdImages/468x60/SC_Banner_17_468x60 px.png';
import SC_Banner_18_468x60 from '../webAdImages/468x60/SC_Banner_18_468x60 px.png';
import SC_Banner_19_468x60 from '../webAdImages/468x60/SC_Banner_19_468x60 px.png';
import SC_Banner_20_468x60 from '../webAdImages/468x60/SC_Banner_20_468x60 px.png';
import SC_Banner_21_468x60 from '../webAdImages/468x60/SC_Banner_21_468x60 px.png';
import SC_Banner_22_468x60 from '../webAdImages/468x60/SC_Banner_22_468x60 px.png';
import SC_Banner_23_468x60 from '../webAdImages/468x60/SC_Banner_23_468x60 px.png';
import SC_Banner_24_468x60 from '../webAdImages/468x60/SC_Banner_24_468x60 px.png';
import SC_Banner_25_468x60 from '../webAdImages/468x60/SC_Banner_25_468x60 px.png';
import SC_Banner_26_468x60 from '../webAdImages/468x60/SC_Banner_26_468x60 px.png';
import SC_Banner_27_468x60 from '../webAdImages/468x60/SC_Banner_27_468x60 px.png';

// Imports for 715x130 images
import SC_Banner_1_715x130 from '../webAdImages/715x130/SC_Banner_1_715x130 px.png';
import SC_Banner_2_715x130 from '../webAdImages/715x130/SC_Banner_2_715x130 px.png';
import SC_Banner_3_715x130 from '../webAdImages/715x130/SC_Banner_3_715x130 px.png';
import SC_Banner_4_715x130 from '../webAdImages/715x130/SC_Banner_4_715x130 px.png';
import SC_Banner_5_715x130 from '../webAdImages/715x130/SC_Banner_5_715x130 px.png';
import SC_Banner_7_715x130 from '../webAdImages/715x130/SC_Banner_7_715x130 px.png';
import SC_Banner_8_715x130 from '../webAdImages/715x130/SC_Banner_8_715x130 px.png';
import SC_Banner_9_715x130 from '../webAdImages/715x130/SC_Banner_9_715x130 px.png';
import SC_Banner_10_715x130 from '../webAdImages/715x130/SC_Banner_10_715x130 px.png';
import SC_Banner_11_715x130 from '../webAdImages/715x130/SC_Banner_11_715x130 px.png';
import SC_Banner_12_715x130 from '../webAdImages/715x130/SC_Banner_12_715x130 px.png';
import SC_Banner_16_715x130 from '../webAdImages/715x130/SC_Banner_16_715x130 px.png';
import SC_Banner_17_715x130 from '../webAdImages/715x130/SC_Banner_17_715x130 px.png';
import SC_Banner_18_715x130 from '../webAdImages/715x130/SC_Banner_18_715x130 px.png';
import SC_Banner_19_715x130 from '../webAdImages/715x130/SC_Banner_19_715x130 px.png';
import SC_Banner_20_715x130 from '../webAdImages/715x130/SC_Banner_20_715x130 px.png';
import SC_Banner_21_715x130 from '../webAdImages/715x130/SC_Banner_21_715x130 px.png';
import SC_Banner_22_715x130 from '../webAdImages/715x130/SC_Banner_22_715x130 px.png';
import SC_Banner_23_715x130 from '../webAdImages/715x130/SC_Banner_23_715x130 px.png';
import SC_Banner_24_715x130 from '../webAdImages/715x130/SC_Banner_24_715x130 px.png';
import SC_Banner_25_715x130 from '../webAdImages/715x130/SC_Banner_25_715x130 px.png';
import SC_Banner_26_715x130 from '../webAdImages/715x130/SC_Banner_26_715x130 px.png';
import SC_Banner_27_715x130 from '../webAdImages/715x130/SC_Banner_27_715x130 px.png';

// Imports for 728x90 images
import SC_Banner_1_728x90 from '../webAdImages/728x90/SC_Banner_1_728x90 px.png';
import SC_Banner_2_728x90 from '../webAdImages/728x90/SC_Banner_2_728x90 px.png';
import SC_Banner_3_728x90 from '../webAdImages/728x90/SC_Banner_3_728x90 px.png';
import SC_Banner_4_728x90 from '../webAdImages/728x90/SC_Banner_4_728x90 px.png';
import SC_Banner_5_728x90 from '../webAdImages/728x90/SC_Banner_5_728x90 px.png';
import SC_Banner_7_728x90 from '../webAdImages/728x90/SC_Banner_7_728x90 px.png';
import SC_Banner_8_728x90 from '../webAdImages/728x90/SC_Banner_8_728x90 px.png';
import SC_Banner_9_728x90 from '../webAdImages/728x90/SC_Banner_9_728x90 px.png';
import SC_Banner_10_728x90 from '../webAdImages/728x90/SC_Banner_10_728x90 px.png';
import SC_Banner_11_728x90 from '../webAdImages/728x90/SC_Banner_11_728x90 px.png';
import SC_Banner_12_728x90 from '../webAdImages/728x90/SC_Banner_12_728x90 px.png';
import SC_Banner_16_728x90 from '../webAdImages/728x90/SC_Banner_16_728x90 px.png';
import SC_Banner_17_728x90 from '../webAdImages/728x90/SC_Banner_17_728x90 px.png';
import SC_Banner_18_728x90 from '../webAdImages/728x90/SC_Banner_18_728x90 px.png';
import SC_Banner_19_728x90 from '../webAdImages/728x90/SC_Banner_19_728x90 px.png';
import SC_Banner_20_728x90 from '../webAdImages/728x90/SC_Banner_20_728x90 px.png';
import SC_Banner_21_728x90 from '../webAdImages/728x90/SC_Banner_21_728x90 px.png';
import SC_Banner_22_728x90 from '../webAdImages/728x90/SC_Banner_22_728x90 px.png';
import SC_Banner_23_728x90 from '../webAdImages/728x90/SC_Banner_23_728x90 px.png';
import SC_Banner_24_728x90 from '../webAdImages/728x90/SC_Banner_24_728x90 px.png';
import SC_Banner_25_728x90 from '../webAdImages/728x90/SC_Banner_25_728x90 px.png';
import SC_Banner_26_728x90 from '../webAdImages/728x90/SC_Banner_26_728x90 px.png';
import SC_Banner_27_728x90 from '../webAdImages/728x90/SC_Banner_27_728x90 px.png';

import SC_Banner_1_970x90 from '../webAdImages/970x90/SC_Banner_1_970x90 px.png';
import SC_Banner_2_970x90 from '../webAdImages/970x90/SC_Banner_2_970x90 px.png';
import SC_Banner_3_970x90 from '../webAdImages/970x90/SC_Banner_3_970x90 px.png';
import SC_Banner_4_970x90 from '../webAdImages/970x90/SC_Banner_4_970x90 px.png';
import SC_Banner_5_970x90 from '../webAdImages/970x90/SC_Banner_5_970x90 px.png';
import SC_Banner_7_970x90 from '../webAdImages/970x90/SC_Banner_7_970x90 px.png';
import SC_Banner_8_970x90 from '../webAdImages/970x90/SC_Banner_8_970x90 px.png';
import SC_Banner_9_970x90 from '../webAdImages/970x90/SC_Banner_9_970x90 px.png';
import SC_Banner_10_970x90 from '../webAdImages/970x90/SC_Banner_10_970x90 px.png';
import SC_Banner_11_970x90 from '../webAdImages/970x90/SC_Banner_11_970x90 px.png';
import SC_Banner_12_970x90 from '../webAdImages/970x90/SC_Banner_12_970x90 px.png';
import SC_Banner_16_970x90 from '../webAdImages/970x90/SC_Banner_16_970x90 px.png';
import SC_Banner_17_970x90 from '../webAdImages/970x90/SC_Banner_17_970x90 px.png';
import SC_Banner_18_970x90 from '../webAdImages/970x90/SC_Banner_18_970x90 px.png';
import SC_Banner_19_970x90 from '../webAdImages/970x90/SC_Banner_19_970x90 px.png';
import SC_Banner_20_970x90 from '../webAdImages/970x90/SC_Banner_20_970x90 px.png';
import SC_Banner_21_970x90 from '../webAdImages/970x90/SC_Banner_21_970x90 px.png';
import SC_Banner_22_970x90 from '../webAdImages/970x90/SC_Banner_22_970x90 px.png';
import SC_Banner_23_970x90 from '../webAdImages/970x90/SC_Banner_23_970x90 px.png';
import SC_Banner_24_970x90 from '../webAdImages/970x90/SC_Banner_24_970x90 px.png';
import SC_Banner_25_970x90 from '../webAdImages/970x90/SC_Banner_25_970x90 px.png';
import SC_Banner_26_970x90 from '../webAdImages/970x90/SC_Banner_26_970x90 px.png';
import SC_Banner_27_970x90 from '../webAdImages/970x90/SC_Banner_27_970x90 px.png';

import SC_Banner_1_970x250 from '../webAdImages/970x250/SC_Banner_1_970x250 px.png';
import SC_Banner_2_970x250 from '../webAdImages/970x250/SC_Banner_2_970x250 px.png';
import SC_Banner_3_970x250 from '../webAdImages/970x250/SC_Banner_3_970x250 px.png';
import SC_Banner_4_970x250 from '../webAdImages/970x250/SC_Banner_4_970x250 px.png';
import SC_Banner_5_970x250 from '../webAdImages/970x250/SC_Banner_5_970x250 px.png';
import SC_Banner_7_970x250 from '../webAdImages/970x250/SC_Banner_7_970x250 px.png';
import SC_Banner_8_970x250 from '../webAdImages/970x250/SC_Banner_8_970x250 px.png';
import SC_Banner_9_970x250 from '../webAdImages/970x250/SC_Banner_9_970x250 px.png';
import SC_Banner_10_970x250 from '../webAdImages/970x250/SC_Banner_10_970x250 px.png';
import SC_Banner_11_970x250 from '../webAdImages/970x250/SC_Banner_11_970x250 px.png';
import SC_Banner_12_970x250 from '../webAdImages/970x250/SC_Banner_12_970x250 px.png';
import SC_Banner_16_970x250 from '../webAdImages/970x250/SC_Banner_16_970x250 px.png';
import SC_Banner_17_970x250 from '../webAdImages/970x250/SC_Banner_17_970x250 px.png';
import SC_Banner_18_970x250 from '../webAdImages/970x250/SC_Banner_18_970x250 px.png';
import SC_Banner_19_970x250 from '../webAdImages/970x250/SC_Banner_19_970x250 px.png';
import SC_Banner_20_970x250 from '../webAdImages/970x250/SC_Banner_20_970x250 px.png';
import SC_Banner_21_970x250 from '../webAdImages/970x250/SC_Banner_21_970x250 px.png';
import SC_Banner_22_970x250 from '../webAdImages/970x250/SC_Banner_22_970x250 px.png';
import SC_Banner_23_970x250 from '../webAdImages/970x250/SC_Banner_23_970x250 px.png';
import SC_Banner_24_970x250 from '../webAdImages/970x250/SC_Banner_24_970x250 px.png';
import SC_Banner_25_970x250 from '../webAdImages/970x250/SC_Banner_25_970x250 px.png';
import SC_Banner_26_970x250 from '../webAdImages/970x250/SC_Banner_26_970x250 px.png';
import SC_Banner_27_970x250 from '../webAdImages/970x250/SC_Banner_27_970x250 px.png';

import SC_Banner_1_1208x744 from '../webAdImages/1208x744/SC_Banner_1_1208x744 px.png';
import SC_Banner_2_1208x744 from '../webAdImages/1208x744/SC_Banner_2_1208x744 px.png';
import SC_Banner_3_1208x744 from '../webAdImages/1208x744/SC_Banner_3_1208x744 px.png';
import SC_Banner_4_1208x744 from '../webAdImages/1208x744/SC_Banner_4_1208x744 px.png';
import SC_Banner_5_1208x744 from '../webAdImages/1208x744/SC_Banner_5_1208x744 px.png';
import SC_Banner_7_1208x744 from '../webAdImages/1208x744/SC_Banner_7_1208x744 px.png';
import SC_Banner_8_1208x744 from '../webAdImages/1208x744/SC_Banner_8_1208x744 px.png';
import SC_Banner_9_1208x744 from '../webAdImages/1208x744/SC_Banner_9_1208x744 px.png';
import SC_Banner_10_1208x744 from '../webAdImages/1208x744/SC_Banner_10_1208x744 px.png';
import SC_Banner_11_1208x744 from '../webAdImages/1208x744/SC_Banner_11_1208x744 px.png';
import SC_Banner_12_1208x744 from '../webAdImages/1208x744/SC_Banner_12_1208x744 px.png';
import SC_Banner_16_1208x744 from '../webAdImages/1208x744/SC_Banner_16_1208x744 px.png';
import SC_Banner_17_1208x744 from '../webAdImages/1208x744/SC_Banner_17_1208x744 px.png';
import SC_Banner_18_1208x744 from '../webAdImages/1208x744/SC_Banner_18_1208x744 px.png';
import SC_Banner_19_1208x744 from '../webAdImages/1208x744/SC_Banner_19_1208x744 px.png';
import SC_Banner_20_1208x744 from '../webAdImages/1208x744/SC_Banner_20_1208x744 px.png';
import SC_Banner_21_1208x744 from '../webAdImages/1208x744/SC_Banner_21_1208x744 px.png';
import SC_Banner_22_1208x744 from '../webAdImages/1208x744/SC_Banner_22_1208x744 px.png';
import SC_Banner_23_1208x744 from '../webAdImages/1208x744/SC_Banner_23_1208x744 px.png';
import SC_Banner_24_1208x744 from '../webAdImages/1208x744/SC_Banner_24_1208x744 px.png';
import SC_Banner_25_1208x744 from '../webAdImages/1208x744/SC_Banner_25_1208x744 px.png';
import SC_Banner_26_1208x744 from '../webAdImages/1208x744/SC_Banner_26_1208x744 px.png';
import SC_Banner_27_1208x744 from '../webAdImages/1208x744/SC_Banner_27_1208x744 px.png';

import SC_Banner_1_1272x792 from '../webAdImages/1272x792/SC_Banner_1_1272x792 px.png';
import SC_Banner_2_1272x792 from '../webAdImages/1272x792/SC_Banner_2_1272x792 px.png';
import SC_Banner_3_1272x792 from '../webAdImages/1272x792/SC_Banner_3_1272x792 px.png';
import SC_Banner_4_1272x792 from '../webAdImages/1272x792/SC_Banner_4_1272x792 px.png';
import SC_Banner_7_1272x792 from '../webAdImages/1272x792/SC_Banner_7_1272x792 px.png';
import SC_Banner_8_1272x792 from '../webAdImages/1272x792/SC_Banner_8_1272x792 px.png';
import SC_Banner_9_1272x792 from '../webAdImages/1272x792/SC_Banner_9_1272x792 px.png';
import SC_Banner_10_1272x792 from '../webAdImages/1272x792/SC_Banner_10_1272x792 px.png';
import SC_Banner_11_1272x792 from '../webAdImages/1272x792/SC_Banner_11_1272x792 px.png';
import SC_Banner_12_1272x792 from '../webAdImages/1272x792/SC_Banner_12_1272x792 px.png';
import SC_Banner_16_1272x792 from '../webAdImages/1272x792/SC_Banner_16_1272x792 px.png';
import SC_Banner_17_1272x792 from '../webAdImages/1272x792/SC_Banner_17_1272x792 px.png';
import SC_Banner_18_1272x792 from '../webAdImages/1272x792/SC_Banner_18_1272x792 px.png';
import SC_Banner_19_1272x792 from '../webAdImages/1272x792/SC_Banner_19_1272x792 px.png';
import SC_Banner_20_1272x792 from '../webAdImages/1272x792/SC_Banner_20_1272x792 px.png';
import SC_Banner_21_1272x792 from '../webAdImages/1272x792/SC_Banner_21_1272x792 px.png';
import SC_Banner_22_1272x792 from '../webAdImages/1272x792/SC_Banner_22_1272x792 px.png';
import SC_Banner_23_1272x792 from '../webAdImages/1272x792/SC_Banner_23_1272x792 px.png';
import SC_Banner_24_1272x792 from '../webAdImages/1272x792/SC_Banner_24_1272x792 px.png';
import SC_Banner_25_1272x792 from '../webAdImages/1272x792/SC_Banner_25_1272x792 px.png';
import SC_Banner_26_1272x792 from '../webAdImages/1272x792/SC_Banner_26_1272x792 px.png';
import SC_Banner_27_1272x792 from '../webAdImages/1272x792/SC_Banner_27_1272x792 px.png';

import SC_Banner_1_1500x500 from '../webAdImages/1500x500/SC_Banner_1_1500x500 px.png';
import SC_Banner_2_1500x500 from '../webAdImages/1500x500/SC_Banner_2_1500x500 px.png';
import SC_Banner_3_1500x500 from '../webAdImages/1500x500/SC_Banner_3_1500x500 px.png';
import SC_Banner_4_1500x500 from '../webAdImages/1500x500/SC_Banner_4_1500x500 px.png';
import SC_Banner_5_1500x500 from '../webAdImages/1500x500/SC_Banner_5_1500x500 px.png';
import SC_Banner_7_1500x500 from '../webAdImages/1500x500/SC_Banner_7_1500x500 px.png';
import SC_Banner_8_1500x500 from '../webAdImages/1500x500/SC_Banner_8_1500x500 px.png';
import SC_Banner_9_1500x500 from '../webAdImages/1500x500/SC_Banner_9_1500x500 px.png';
import SC_Banner_10_1500x500 from '../webAdImages/1500x500/SC_Banner_10_1500x500 px.png';
import SC_Banner_11_1500x500 from '../webAdImages/1500x500/SC_Banner_11_1500x500 px.png';
import SC_Banner_12_1500x500 from '../webAdImages/1500x500/SC_Banner_12_1500x500 px.png';
import SC_Banner_16_1500x500 from '../webAdImages/1500x500/SC_Banner_16_1500x500 px.png';
import SC_Banner_17_1500x500 from '../webAdImages/1500x500/SC_Banner_17_1500x500 px.png';
import SC_Banner_18_1500x500 from '../webAdImages/1500x500/SC_Banner_18_1500x500 px.png';
import SC_Banner_19_1500x500 from '../webAdImages/1500x500/SC_Banner_19_1500x500 px.png';
import SC_Banner_20_1500x500 from '../webAdImages/1500x500/SC_Banner_20_1500x500 px.png';
import SC_Banner_21_1500x500 from '../webAdImages/1500x500/SC_Banner_21_1500x500 px.png';
import SC_Banner_22_1500x500 from '../webAdImages/1500x500/SC_Banner_22_1500x500 px.png';
import SC_Banner_23_1500x500 from '../webAdImages/1500x500/SC_Banner_23_1500x500 px.png';
import SC_Banner_24_1500x500 from '../webAdImages/1500x500/SC_Banner_24_1500x500 px.png';
import SC_Banner_25_1500x500 from '../webAdImages/1500x500/SC_Banner_25_1500x500 px.png';
import SC_Banner_26_1500x500 from '../webAdImages/1500x500/SC_Banner_26_1500x500 px.png';
import SC_Banner_27_1500x500 from '../webAdImages/1500x500/SC_Banner_27_1500x500 px.png';

import SC_Banner_1_1880x360 from '../webAdImages/1880x360/SC_Banner_1_1880x360 px.png';
import SC_Banner_2_1880x360 from '../webAdImages/1880x360/SC_Banner_2_1880x360 px.png';
import SC_Banner_3_1880x360 from '../webAdImages/1880x360/SC_Banner_3_1880x360 px.png';
import SC_Banner_4_1880x360 from '../webAdImages/1880x360/SC_Banner_4_1880x360 px.png';
import SC_Banner_5_1880x360 from '../webAdImages/1880x360/SC_Banner_5_1880x360 px.png';
import SC_Banner_7_1880x360 from '../webAdImages/1880x360/SC_Banner_7_1880x360 px.png';
import SC_Banner_8_1880x360 from '../webAdImages/1880x360/SC_Banner_8_1880x360 px.png';
import SC_Banner_9_1880x360 from '../webAdImages/1880x360/SC_Banner_9_1880x360 px.png';
import SC_Banner_10_1880x360 from '../webAdImages/1880x360/SC_Banner_10_1880x360 px.png';
import SC_Banner_11_1880x360 from '../webAdImages/1880x360/SC_Banner_11_1880x360 px.png';
import SC_Banner_12_1880x360 from '../webAdImages/1880x360/SC_Banner_12_1880x360 px.png';
import SC_Banner_16_1880x360 from '../webAdImages/1880x360/SC_Banner_16_1880x360 px.png';
import SC_Banner_17_1880x360 from '../webAdImages/1880x360/SC_Banner_17_1880x360 px.png';
import SC_Banner_18_1880x360 from '../webAdImages/1880x360/SC_Banner_18_1880x360 px.png';
import SC_Banner_19_1880x360 from '../webAdImages/1880x360/SC_Banner_19_1880x360 px.png';
import SC_Banner_20_1880x360 from '../webAdImages/1880x360/SC_Banner_20_1880x360 px.png';
import SC_Banner_21_1880x360 from '../webAdImages/1880x360/SC_Banner_21_1880x360 px.png';
import SC_Banner_22_1880x360 from '../webAdImages/1880x360/SC_Banner_22_1880x360 px.png';
import SC_Banner_23_1880x360 from '../webAdImages/1880x360/SC_Banner_23_1880x360 px.png';
import SC_Banner_24_1880x360 from '../webAdImages/1880x360/SC_Banner_24_1880x360 px.png';
import SC_Banner_25_1880x360 from '../webAdImages/1880x360/SC_Banner_25_1880x360 px.png';
import SC_Banner_26_1880x360 from '../webAdImages/1880x360/SC_Banner_26_1880x360 px.png';
import SC_Banner_27_1880x360 from '../webAdImages/1880x360/SC_Banner_27_1880x360 px.png';

import SC_Banner_1_2400x1350 from '../webAdImages/2400x1350/SC_Banner_1_2400x1350 px.png';
import SC_Banner_2_2400x1350 from '../webAdImages/2400x1350/SC_Banner_2_2400x1350 px.png';
import SC_Banner_3_2400x1350 from '../webAdImages/2400x1350/SC_Banner_3_2400x1350 px.png';
import SC_Banner_4_2400x1350 from '../webAdImages/2400x1350/SC_Banner_4_2400x1350 px.png';
import SC_Banner_5_2400x1350 from '../webAdImages/2400x1350/SC_Banner_5_2400x1350 px.png';
import SC_Banner_7_2400x1350 from '../webAdImages/2400x1350/SC_Banner_7_2400x1350 px.png';
import SC_Banner_8_2400x1350 from '../webAdImages/2400x1350/SC_Banner_8_2400x1350 px.png';
import SC_Banner_9_2400x1350 from '../webAdImages/2400x1350/SC_Banner_9_2400x1350 px.png';
import SC_Banner_10_2400x1350 from '../webAdImages/2400x1350/SC_Banner_10_2400x1350 px.png';
import SC_Banner_11_2400x1350 from '../webAdImages/2400x1350/SC_Banner_11_2400x1350 px.png';
import SC_Banner_12_2400x1350 from '../webAdImages/2400x1350/SC_Banner_12_2400x1350 px.png';
import SC_Banner_16_2400x1350 from '../webAdImages/2400x1350/SC_Banner_16_2400x1350 px.png';
import SC_Banner_17_2400x1350 from '../webAdImages/2400x1350/SC_Banner_17_2400x1350 px.png';
import SC_Banner_18_2400x1350 from '../webAdImages/2400x1350/SC_Banner_18_2400x1350 px.png';
import SC_Banner_19_2400x1350 from '../webAdImages/2400x1350/SC_Banner_19_2400x1350 px.png';
import SC_Banner_20_2400x1350 from '../webAdImages/2400x1350/SC_Banner_20_2400x1350 px.png';
import SC_Banner_21_2400x1350 from '../webAdImages/2400x1350/SC_Banner_21_2400x1350 px.png';
import SC_Banner_22_2400x1350 from '../webAdImages/2400x1350/SC_Banner_22_2400x1350 px.png';
import SC_Banner_23_2400x1350 from '../webAdImages/2400x1350/SC_Banner_23_2400x1350 px.png';
import SC_Banner_24_2400x1350 from '../webAdImages/2400x1350/SC_Banner_24_2400x1350 px.png';
import SC_Banner_25_2400x1350 from '../webAdImages/2400x1350/SC_Banner_25_2400x1350 px.png';
import SC_Banner_26_2400x1350 from '../webAdImages/2400x1350/SC_Banner_26_2400x1350 px.png';
import SC_Banner_27_2400x1350 from '../webAdImages/2400x1350/SC_Banner_27_2400x1350 px.png';

import Get_the_app_IG_1 from '../social/Get the app_IG_1.png';
import Get_the_app_IG_2 from '../social/Get the app_IG_2.png';
import Hotel_IG_1 from '../social/Hotel_IG_1.png';
import Hotel_IG_2 from '../social/Hotel_IG_2.png';
import Hotel_IG_3 from '../social/Hotel_IG_3.png';
import InstaBanner_AW_1 from '../social/InstaBanner_AW_1.png';
import InstaBanner_AW_2 from '../social/InstaBanner_AW_2.png';
import InstaBanner_AW_3 from '../social/InstaBanner_AW_3.png';
import InstaBanner_AW_4 from '../social/InstaBanner_AW_4.png';
import InstaBanner_AW_5 from '../social/InstaBanner_AW_5.png';
import InstaBanner_AW_7 from '../social/InstaBanner_AW_7.png';
import InstaBanner_AW_8 from '../social/InstaBanner_AW_8.png';
import InstaBanner_AW_9 from '../social/InstaBanner_AW_9.png';
import InstaBanner_AW_10 from '../social/InstaBanner_AW_10.png';
import InstaBanner_AW_11 from '../social/InstaBanner_AW_11.png';
import InstaBanner_AW_12 from '../social/InstaBanner_AW_12.png';
import InstaBanner_AW_16 from '../social/InstaBanner_AW_16.png';
import InstaBanner_AW_17 from '../social/InstaBanner_AW_17.png';
import InstaBanner_AW_18 from '../social/InstaBanner_AW_18.png';
import InstaBanner_AW_19 from '../social/InstaBanner_AW_19.png';
import InstaBanner_AW_20 from '../social/InstaBanner_AW_20.png';
import InstaBanner_AW_21 from '../social/InstaBanner_AW_21.png';
import InstaBanner_AW_22 from '../social/InstaBanner_AW_22.png';
import InstaBanner_AW_23 from '../social/InstaBanner_AW_23.png';
import InstaBanner_AW_24 from '../social/InstaBanner_AW_24.png';
import InstaBanner_AW_25 from '../social/InstaBanner_AW_25.png';
import InstaBanner_AW_26 from '../social/InstaBanner_AW_26.png';
import InstaBanner_AW_27 from '../social/InstaBanner_AW_27.png';
import Restaurant_IG_1 from '../social/Restaurant_IG_1.png';
import SC_Banner_Approved_1 from '../social/SC_Banner_Approved_1.png';
import SC_Banner_Approved_2 from '../social/SC_Banner_Approved_2.png';
import SC_Banner_Approved_3 from '../social/SC_Banner_Approved_3.png';
import SC_Banner_Approved_4 from '../social/SC_Banner_Approved_4.png';
import SC_Banner_Approved_5 from '../social/SC_Banner_Approved_5.png';
import SC_Banner_Approved_7 from '../social/SC_Banner_Approved_7.png';
import SC_Banner_Approved_8 from '../social/SC_Banner_Approved_8.png';
import SC_Banner_Approved_9 from '../social/SC_Banner_Approved_9.png';
import SC_Banner_Approved_10 from '../social/SC_Banner_Approved_10.png';
import SC_Banner_Approved_11 from '../social/SC_Banner_Approved_11.png';
import SC_Banner_Approved_12 from '../social/SC_Banner_Approved_12.png';
import SC_Banner_Approved_16 from '../social/SC_Banner_Approved_16.png';
import SC_Banner_Approved_17 from '../social/SC_Banner_Approved_17.png';
import SC_Banner_Approved_18 from '../social/SC_Banner_Approved_18.png';
import SC_Banner_Approved_19 from '../social/SC_Banner_Approved_19.png';
import SC_Banner_Approved_20 from '../social/SC_Banner_Approved_20.png';
import SC_Banner_Approved_21 from '../social/SC_Banner_Approved_21.png';
import SC_Banner_Approved_22 from '../social/SC_Banner_Approved_22.png';
import SC_Banner_Approved_23 from '../social/SC_Banner_Approved_23.png';
import SC_Banner_Approved_25 from '../social/SC_Banner_Approved_25.png';
import SC_Banner_Approved_26 from '../social/SC_Banner_Approved_26.png';
import SC_Banner_Approved_27 from '../social/SC_Banner_Approved_27.png';
import Travel_IG_1 from '../social/Travel_IG_1.png';

export const web120x600 = [
  SC_Banner_1_120x600,
  SC_Banner_2_120x600,
  SC_Banner_3_120x600,
  SC_Banner_4_120x600,
  SC_Banner_5_120x600,
  SC_Banner_7_120x600,
  SC_Banner_8_120x600,
  SC_Banner_9_120x600,
  SC_Banner_10_120x600,
  SC_Banner_11_120x600,
  SC_Banner_12_120x600,
  SC_Banner_16_120x600,
  SC_Banner_17_120x600,
  SC_Banner_18_120x600,
  SC_Banner_20_120x600,
  SC_Banner_21_120x600,
  SC_Banner_22_120x600,
  SC_Banner_23_120x600,
  SC_Banner_24_120x600,
  SC_Banner_25_120x600,
];

export const web160x600 = [
  SC_Banner_1_160x600,
  SC_Banner_2_160x600,
  SC_Banner_3_160x600,
  SC_Banner_4_160x600,
  SC_Banner_5_160x600,
  SC_Banner_7_160x600,
  SC_Banner_8_160x600,
  SC_Banner_9_160x600,
  SC_Banner_10_160x600,
  SC_Banner_11_160x600,
  SC_Banner_12_160x600,
  SC_Banner_16_160x600,
  SC_Banner_17_160x600,
  SC_Banner_18_160x600,
  SC_Banner_19_160x600,
  SC_Banner_20_160x600,
  SC_Banner_21_160x600,
  SC_Banner_22_160x600,
  SC_Banner_23_160x600,
  SC_Banner_24_160x600,
  SC_Banner_25_160x600,
  SC_Banner_26_160x600,
  SC_Banner_27_160x600,
];

export const web250x250 = [
  SC_Banner_1_250x250,
  SC_Banner_2_250x250,
  SC_Banner_3_250x250,
  SC_Banner_4_250x250,
  SC_Banner_5_250x250,
  SC_Banner_7_250x250,
  SC_Banner_8_250x250,
  SC_Banner_9_250x250,
  SC_Banner_10_250x250,
  SC_Banner_11_250x250,
  SC_Banner_12_250x250,
  SC_Banner_16_250x250,
  SC_Banner_17_250x250,
  SC_Banner_18_250x250,
  SC_Banner_19_250x250,
  SC_Banner_20_250x250,
  SC_Banner_21_250x250,
  SC_Banner_22_250x250,
  SC_Banner_23_250x250,
  SC_Banner_24_250x250,
  SC_Banner_25_250x250,
  SC_Banner_26_250x250,
  SC_Banner_27_250x250,
];
export const web300x250 = [
  SC_Banner_1_300x250,
  SC_Banner_2_300x250,
  SC_Banner_3_300x250,
  SC_Banner_4_300x250,
  SC_Banner_5_300x250,
  SC_Banner_7_300x250,
  SC_Banner_8_300x250,
  SC_Banner_9_300x250,
  SC_Banner_10_300x250,
  SC_Banner_11_300x250,
  SC_Banner_12_300x250,
  SC_Banner_16_300x250,
  SC_Banner_17_300x250,
  SC_Banner_18_300x250,
  SC_Banner_19_300x250,
  SC_Banner_20_300x250,
  SC_Banner_21_300x250,
  SC_Banner_22_300x250,
  SC_Banner_23_300x250,
  SC_Banner_24_300x250,
  SC_Banner_25_300x250,
  SC_Banner_26_300x250,
  SC_Banner_27_300x250,
];

export const web300x600 = [
  SC_Banner_1_300x600,
  SC_Banner_2_300x600,
  SC_Banner_3_300x600,
  SC_Banner_4_300x600,
  SC_Banner_5_300x600,
  SC_Banner_7_300x600,
  SC_Banner_8_300x600,
  SC_Banner_9_300x600,
  SC_Banner_10_300x600,
  SC_Banner_11_300x600,
  SC_Banner_12_300x600,
  SC_Banner_16_300x600,
  SC_Banner_17_300x600,
  SC_Banner_18_300x600,
  SC_Banner_19_300x600,
  SC_Banner_20_300x600,
  SC_Banner_21_300x600,
  SC_Banner_22_300x600,
  SC_Banner_23_300x600,
  SC_Banner_24_300x600,
  SC_Banner_25_300x600,
  SC_Banner_26_300x600,
  SC_Banner_27_300x600,
];

export const web336x280 = [
  SC_Banner_1_336x280,
  SC_Banner_2_336x280,
  SC_Banner_3_336x280,
  SC_Banner_4_336x280,
  SC_Banner_5_336x280,
  SC_Banner_7_336x280,
  SC_Banner_8_336x280,
  SC_Banner_9_336x280,
  SC_Banner_10_336x280,
  SC_Banner_11_336x280,
  SC_Banner_12_336x280,
  SC_Banner_16_336x280,
  SC_Banner_17_336x280,
  SC_Banner_18_336x280,
  SC_Banner_19_336x280,
  SC_Banner_20_336x280,
  SC_Banner_21_336x280,
  SC_Banner_22_336x280,
  SC_Banner_23_336x280,
  SC_Banner_24_336x280,
  SC_Banner_25_336x280,
  SC_Banner_26_336x280,
  SC_Banner_27_336x280,
];

export const web420x250 = [
  SC_Banner_1_420x250,
  SC_Banner_2_420x250,
  SC_Banner_3_420x250,
  SC_Banner_4_420x250,
  SC_Banner_5_420x250,
  SC_Banner_7_420x250,
  SC_Banner_8_420x250,
  SC_Banner_9_420x250,
  SC_Banner_10_420x250,
  SC_Banner_11_420x250,
  SC_Banner_12_420x250,
  SC_Banner_16_420x250,
  SC_Banner_17_420x250,
  SC_Banner_18_420x250,
  SC_Banner_19_420x250,
  SC_Banner_20_420x250,
  SC_Banner_21_420x250,
  SC_Banner_22_420x250,
  SC_Banner_23_420x250,
  SC_Banner_24_420x250,
  SC_Banner_25_420x250,
  SC_Banner_26_420x250,
  SC_Banner_27_420x250,
];

export const web468x60 = [
  SC_Banner_1_468x60,
  SC_Banner_2_468x60,
  SC_Banner_3_468x60,
  SC_Banner_4_468x60,
  SC_Banner_5_468x60,
  SC_Banner_7_468x60,
  SC_Banner_8_468x60,
  SC_Banner_9_468x60,
  SC_Banner_10_468x60,
  SC_Banner_11_468x60,
  SC_Banner_12_468x60,
  SC_Banner_16_468x60,
  SC_Banner_17_468x60,
  SC_Banner_18_468x60,
  SC_Banner_19_468x60,
  SC_Banner_20_468x60,
  SC_Banner_21_468x60,
  SC_Banner_22_468x60,
  SC_Banner_23_468x60,
  SC_Banner_24_468x60,
  SC_Banner_25_468x60,
  SC_Banner_26_468x60,
  SC_Banner_27_468x60,
];

export const web715x130 = [
  SC_Banner_1_715x130,
  SC_Banner_2_715x130,
  SC_Banner_3_715x130,
  SC_Banner_4_715x130,
  SC_Banner_5_715x130,
  SC_Banner_7_715x130,
  SC_Banner_8_715x130,
  SC_Banner_9_715x130,
  SC_Banner_10_715x130,
  SC_Banner_11_715x130,
  SC_Banner_12_715x130,
  SC_Banner_16_715x130,
  SC_Banner_17_715x130,
  SC_Banner_18_715x130,
  SC_Banner_19_715x130,
  SC_Banner_20_715x130,
  SC_Banner_21_715x130,
  SC_Banner_22_715x130,
  SC_Banner_23_715x130,
  SC_Banner_24_715x130,
  SC_Banner_25_715x130,
  SC_Banner_26_715x130,
  SC_Banner_27_715x130,
];

export const web728x90 = [
  SC_Banner_1_728x90,
  SC_Banner_2_728x90,
  SC_Banner_3_728x90,
  SC_Banner_4_728x90,
  SC_Banner_5_728x90,
  SC_Banner_7_728x90,
  SC_Banner_8_728x90,
  SC_Banner_9_728x90,
  SC_Banner_10_728x90,
  SC_Banner_11_728x90,
  SC_Banner_12_728x90,
  SC_Banner_16_728x90,
  SC_Banner_17_728x90,
  SC_Banner_18_728x90,
  SC_Banner_19_728x90,
  SC_Banner_20_728x90,
  SC_Banner_21_728x90,
  SC_Banner_22_728x90,
  SC_Banner_23_728x90,
  SC_Banner_24_728x90,
  SC_Banner_25_728x90,
  SC_Banner_26_728x90,
  SC_Banner_27_728x90,
];

export const web970x90 = [
  SC_Banner_1_970x90,
  SC_Banner_2_970x90,
  SC_Banner_3_970x90,
  SC_Banner_4_970x90,
  SC_Banner_5_970x90,
  SC_Banner_7_970x90,
  SC_Banner_8_970x90,
  SC_Banner_9_970x90,
  SC_Banner_10_970x90,
  SC_Banner_11_970x90,
  SC_Banner_12_970x90,
  SC_Banner_16_970x90,
  SC_Banner_17_970x90,
  SC_Banner_18_970x90,
  SC_Banner_19_970x90,
  SC_Banner_20_970x90,
  SC_Banner_21_970x90,
  SC_Banner_22_970x90,
  SC_Banner_23_970x90,
  SC_Banner_24_970x90,
  SC_Banner_25_970x90,
  SC_Banner_26_970x90,
  SC_Banner_27_970x90,
];

export const web970x250 = [
  SC_Banner_1_970x250,
  SC_Banner_2_970x250,
  SC_Banner_3_970x250,
  SC_Banner_4_970x250,
  SC_Banner_5_970x250,
  SC_Banner_7_970x250,
  SC_Banner_8_970x250,
  SC_Banner_9_970x250,
  SC_Banner_10_970x250,
  SC_Banner_11_970x250,
  SC_Banner_12_970x250,
  SC_Banner_16_970x250,
  SC_Banner_17_970x250,
  SC_Banner_18_970x250,
  SC_Banner_19_970x250,
  SC_Banner_20_970x250,
  SC_Banner_21_970x250,
  SC_Banner_22_970x250,
  SC_Banner_23_970x250,
  SC_Banner_24_970x250,
  SC_Banner_25_970x250,
  SC_Banner_26_970x250,
  SC_Banner_27_970x250,
];

export const web1208x744 = [
  SC_Banner_1_1208x744,
  SC_Banner_2_1208x744,
  SC_Banner_3_1208x744,
  SC_Banner_4_1208x744,
  SC_Banner_5_1208x744,
  SC_Banner_7_1208x744,
  SC_Banner_8_1208x744,
  SC_Banner_9_1208x744,
  SC_Banner_10_1208x744,
  SC_Banner_11_1208x744,
  SC_Banner_12_1208x744,
  SC_Banner_16_1208x744,
  SC_Banner_17_1208x744,
  SC_Banner_18_1208x744,
  SC_Banner_19_1208x744,
  SC_Banner_20_1208x744,
  SC_Banner_21_1208x744,
  SC_Banner_22_1208x744,
  SC_Banner_23_1208x744,
  SC_Banner_24_1208x744,
  SC_Banner_25_1208x744,
  SC_Banner_26_1208x744,
  SC_Banner_27_1208x744,
];

export const web1272x792 = [
  SC_Banner_1_1272x792,
  SC_Banner_2_1272x792,
  SC_Banner_3_1272x792,
  SC_Banner_4_1272x792,
  SC_Banner_7_1272x792,
  SC_Banner_8_1272x792,
  SC_Banner_9_1272x792,
  SC_Banner_10_1272x792,
  SC_Banner_11_1272x792,
  SC_Banner_12_1272x792,
  SC_Banner_16_1272x792,
  SC_Banner_17_1272x792,
  SC_Banner_18_1272x792,
  SC_Banner_19_1272x792,
  SC_Banner_20_1272x792,
  SC_Banner_21_1272x792,
  SC_Banner_22_1272x792,
  SC_Banner_23_1272x792,
  SC_Banner_24_1272x792,
  SC_Banner_25_1272x792,
  SC_Banner_26_1272x792,
  SC_Banner_27_1272x792,
];

export const web1500x500 = [
  SC_Banner_1_1500x500,
  SC_Banner_2_1500x500,
  SC_Banner_3_1500x500,
  SC_Banner_4_1500x500,
  SC_Banner_5_1500x500,
  SC_Banner_7_1500x500,
  SC_Banner_8_1500x500,
  SC_Banner_9_1500x500,
  SC_Banner_10_1500x500,
  SC_Banner_11_1500x500,
  SC_Banner_12_1500x500,
  SC_Banner_16_1500x500,
  SC_Banner_17_1500x500,
  SC_Banner_18_1500x500,
  SC_Banner_19_1500x500,
  SC_Banner_20_1500x500,
  SC_Banner_21_1500x500,
  SC_Banner_22_1500x500,
  SC_Banner_23_1500x500,
  SC_Banner_24_1500x500,
  SC_Banner_25_1500x500,
  SC_Banner_26_1500x500,
  SC_Banner_27_1500x500,
];

export const web1880x360 = [
  SC_Banner_1_1880x360,
  SC_Banner_2_1880x360,
  SC_Banner_3_1880x360,
  SC_Banner_4_1880x360,
  SC_Banner_5_1880x360,
  SC_Banner_7_1880x360,
  SC_Banner_8_1880x360,
  SC_Banner_9_1880x360,
  SC_Banner_10_1880x360,
  SC_Banner_11_1880x360,
  SC_Banner_12_1880x360,
  SC_Banner_16_1880x360,
  SC_Banner_17_1880x360,
  SC_Banner_18_1880x360,
  SC_Banner_19_1880x360,
  SC_Banner_20_1880x360,
  SC_Banner_21_1880x360,
  SC_Banner_22_1880x360,
  SC_Banner_23_1880x360,
  SC_Banner_24_1880x360,
  SC_Banner_25_1880x360,
  SC_Banner_26_1880x360,
  SC_Banner_27_1880x360,
];

export const web2400x1350 = [
  SC_Banner_1_2400x1350,
  SC_Banner_2_2400x1350,
  SC_Banner_3_2400x1350,
  SC_Banner_4_2400x1350,
  SC_Banner_5_2400x1350,
  SC_Banner_7_2400x1350,
  SC_Banner_8_2400x1350,
  SC_Banner_9_2400x1350,
  SC_Banner_10_2400x1350,
  SC_Banner_11_2400x1350,
  SC_Banner_12_2400x1350,
  SC_Banner_16_2400x1350,
  SC_Banner_17_2400x1350,
  SC_Banner_18_2400x1350,
  SC_Banner_19_2400x1350,
  SC_Banner_20_2400x1350,
  SC_Banner_21_2400x1350,
  SC_Banner_22_2400x1350,
  SC_Banner_23_2400x1350,
  SC_Banner_24_2400x1350,
  SC_Banner_25_2400x1350,
  SC_Banner_26_2400x1350,
  SC_Banner_27_2400x1350,
];

export const socialImages = [
  Get_the_app_IG_1,
  Get_the_app_IG_2,
  Hotel_IG_1,
  Hotel_IG_2,
  Hotel_IG_3,
  InstaBanner_AW_1,
  InstaBanner_AW_2,
  InstaBanner_AW_3,
  InstaBanner_AW_4,
  InstaBanner_AW_5,
  InstaBanner_AW_7,
  InstaBanner_AW_8,
  InstaBanner_AW_9,
  InstaBanner_AW_10,
  InstaBanner_AW_11,
  InstaBanner_AW_12,
  InstaBanner_AW_16,
  InstaBanner_AW_17,
  InstaBanner_AW_18,
  InstaBanner_AW_19,
  InstaBanner_AW_20,
  InstaBanner_AW_21,
  InstaBanner_AW_22,
  InstaBanner_AW_23,
  InstaBanner_AW_24,
  InstaBanner_AW_25,
  InstaBanner_AW_26,
  InstaBanner_AW_27,
  Restaurant_IG_1,
  SC_Banner_Approved_1,
  SC_Banner_Approved_2,
  SC_Banner_Approved_3,
  SC_Banner_Approved_4,
  SC_Banner_Approved_5,
  SC_Banner_Approved_7,
  SC_Banner_Approved_8,
  SC_Banner_Approved_9,
  SC_Banner_Approved_10,
  SC_Banner_Approved_11,
  SC_Banner_Approved_12,
  SC_Banner_Approved_16,
  SC_Banner_Approved_17,
  SC_Banner_Approved_18,
  SC_Banner_Approved_19,
  SC_Banner_Approved_20,
  SC_Banner_Approved_21,
  SC_Banner_Approved_22,
  SC_Banner_Approved_23,
  SC_Banner_Approved_25,
  SC_Banner_Approved_26,
  SC_Banner_Approved_27,
  Travel_IG_1,
];
