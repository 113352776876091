import React from 'react';

import GetInTouchForm from '../b2b/GetInTouchForm';

const Support: React.FC = () => {
  return (
    <GetInTouchForm
      title='Need Help?'
      paragraph={`We're here for you! Contact us with any questions or technical issues, and we'll be happy to assist`}
    />
  );
};

export default Support;
