import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';
import BarchartComp from '../../components/barChart/BarChart';
import Button from '../../components/button/Button';
import { FilterByDateWithFlag } from '../../components/lineChart/LineChart';
import { GetDashboardInfo } from '../../features/partnerSlice';
import {
  GetGraphMemberEarnings,
  GetGraphPartnerEarnings,
  setGraphMemberEarnings,
  setGraphPartnerEarnings,
} from '../../features/reportingSlice';

const PartnerErnings = () => {
  const [activeTab, setActiveTab] = useState<'1' | '2' | '3'>('1');
  const [customDate, setCustomDate] = useState<FilterByDateWithFlag>({
    startDate: '',
    endDate: '',
    typeName: '',
  });
  const { dashboardInfo } = useSelector((state: RootState) => state.partner);
  const { graphPartnerEarnings, graphMemberEarnings, loadingStates } =
    useSelector((state: RootState) => state.reporting);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!dashboardInfo) {
      dispatch(GetDashboardInfo());
    }
  }, [dashboardInfo, dispatch]);
  useEffect(() => {
    const { typeName, ...date } = customDate;
    if (typeName === 'PartnerEarnings') {
      dispatch(GetGraphPartnerEarnings(date));
    } else if (!graphPartnerEarnings && activeTab === '1') {
      dispatch(GetGraphPartnerEarnings());
    }
    if (typeName === 'MemberEarnings') {
      dispatch(GetGraphMemberEarnings(date));
    } else if (!graphMemberEarnings && activeTab === '2') {
      dispatch(GetGraphMemberEarnings());
    }

    if (typeName) {
      setCustomDate(prev => ({ ...prev, typeName: '' }));
    }
  }, [
    activeTab,
    customDate,
    dispatch,
    graphPartnerEarnings,
    graphMemberEarnings,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setGraphMemberEarnings(null));
      dispatch(setGraphPartnerEarnings(null));
    };
  }, [dispatch]);

  return (
    <div className='space-y-10'>
      {/* Toggle Button */}
      <div className='space-y-6 mt-6'>
        <h1 className=' font-bold text-2xl'>Partner Earnings</h1>
        <p className='text-xl font-semibold'>
          {dashboardInfo?.totalPartnerEarnings?.USD?.valueText ?? '0'}
        </p>
      </div>
      <div className='flex gap-10'>
        <Button
          className={` ${
            activeTab === '1'
              ? 'bg-[#00C389] text-white'
              : 'text-black bg-white'
          }    w-full`}
          onClick={() => setActiveTab('1')}
        >
          Partner Earnings
        </Button>
        <Button
          className={` ${
            activeTab === '2'
              ? 'bg-[#00C389] text-white'
              : 'text-black bg-white'
          }    w-full`}
          onClick={() => setActiveTab('2')}
        >
          Member Earnings
        </Button>
      </div>
      {/* Display Charts or List View */}
      <div className='grid grid-cols-1 '>
        {activeTab === '1' && (
          <BarchartComp
            data={graphPartnerEarnings}
            dataKey1='earnings'
            name1='Partner Earnings'
            chartName='PartnerEarnings'
            setCustomDate={setCustomDate}
            dollar={true}
            isOneColumn={true}
            isFiltering={true}
            description='Monthly Partner Earnings'
            isLoading={loadingStates['GetGraphPartnerEarnings']}
          />
        )}
        {activeTab === '2' && (
          <BarchartComp
            data={graphMemberEarnings}
            dataKey1='earnings'
            name1='Average Earnings per Member'
            chartName='MemberEarnings'
            setCustomDate={setCustomDate}
            dollar={true}
            isOneColumn={true}
            isFiltering={true}
            description=' Average Monthly Earning per Active Member'
            isLoading={loadingStates['GetGraphMemberEarnings']}
          />
        )}
      </div>
    </div>
  );
};

export default PartnerErnings;
